import { Flex, Text } from "@chakra-ui/react";
import { brandColorBlue } from "../../GlobalStyles";

export default function SubHeader({ children }) {
  return (
    <Flex
      width={{ base: "calc(100vw - 200px)", lg: "calc(100vw - 240px)" }}
      height={{ base: "75px", lg: "85px" }}
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.200"
      alignItems="center"
      paddingLeft="30px"
      position="fixed"
      top="80px"
      right="0"
      zIndex="3"
    >
      <Text
        fontSize="3xl"
        fontWeight="600"
        color={brandColorBlue}
      >
        {children}
      </Text>
    </Flex>
  );
}
