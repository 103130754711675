import { Button } from "@chakra-ui/react";
import { brandColorBlue } from "../GlobalStyles";

export default function SidebarButton({
  children,
  icon,
  onClick,
  marginTop,
  selected,
}) {
  return (
    <Button
      size="lg"
      marginTop={marginTop}
      variant={selected ? "solid" : "ghost"}
      color={selected ? "white" : brandColorBlue}
      backgroundColor={selected ? "#10B981" : "transparent"}
      _hover={{
        backgroundColor: "#34D399",
        color: "white",
      }}
      leftIcon={icon}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
