import { useContext, useEffect, useState } from "react";
import { HStack, VStack } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import { CollectionCard } from "../../components/AdminComponents";
import { getCollections } from "../../services/Collections";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import { useNavigate } from "react-router-dom";

export default function IndustriaMyCollections() {
  const [collections, setCollections] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    const response = await getCollections(
      data.idToken.jwtToken,
      meetalUser.meetal_user_id,
      meetalUser.user_type,
      logOut
    );
    setPageLoading(false);
    // console.log(JSON.parse(response.data.body));
    setCollections(JSON.parse(response.data.body));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Minhas coletas</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : collections.length > 0 ? (
              <VStack>
                {collections.map((c) => (
                  <CollectionCard
                    key={c.coleta_id}
                    id={c.coleta_id}
                    user_type={meetalUser.user_type}
                    sucateiro_cnpj_nome={c.sucateiro_cnpj_nome}
                    industria_cnpj_nome={c.industria_cnpj_nome}
                    total_weight={c.total_weight}
                    valor_coleta={c.valor_coleta}
                    status={c.coleta_status}
                    coleta_date={c.coleta_date}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyState
                titleText="As suas coletas irão aparecer aqui"
                descriptionText="Caso você possua um contrato ativo com algum comprador, você pode solicitar coletas sempre que necessário."
                onClick={() => navigate("/admin/industria/meus-contratos")}
                buttonText="Solicitar coletas"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
