import { useContext, useEffect, useState } from "react";
import { HStack, SimpleGrid, useToast } from "@chakra-ui/react";
import { getAllSucatas } from "../../services/Sucatas";
import { postOffer } from "../../services/Offers";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import SubHeader from "../../components/Global/SubHeader";
import AdminPageContent from "../../components/Global/AdminPageContent";
import { useDisclosure } from "@chakra-ui/react";
import Footer from "../../components/Global/Footer";
import {
  CardSucateiro,
  MakeOfferDrawer,
} from "../../components/AdminComponents";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import FsLightbox from "fslightbox-react";

export default function SucateiroAllScraps() {
  const [scrapPosts, setScrapPosts] = useState([]);
  const [currentBid, setCurrentBid] = useState("");
  const [chosenScrap, setChosenScrap] = useState("");
  const [materialsOffer, setMaterialsOffer] = useState([]);
  const [minimumOffer, setMinimumOffer] = useState(0);
  const [buttonMakeOfferIsLoading, setButtonMakeOfferIsLoading] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toggler, setToggler] = useState(false);

  const toast = useToast();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    let sucatas = await getAllSucatas(data.idToken.jwtToken, logOut);
    setPageLoading(false);
    if (!meetalUser.is_test) {
      sucatas = sucatas.filter((s) => {
        return !s.is_test;
      });
    }

    setScrapPosts(sucatas);
  }

  // setInterval(() => {
  //   fetchData();
  // }, 60000);

  useEffect(() => {
    fetchData();
  }, []);

  function validateOffer(previous_bid, n_offers) {
    const decodedBid = decodePrice(currentBid);
    if (n_offers > 0) {
      if (decodedBid >= previous_bid * 1.02) {
        return true;
      } else {
        toast({
          title: "Erro ao enviar oferta",
          description: "Sua oferta deve ser maior que o valor mínimo",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
        return false;
      }
    } else if (decodedBid < previous_bid) {
      toast({
        title: "Erro ao enviar oferta",
        description: "Sua oferta deve ser acima do valor atual",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    } else {
      return true;
    }
  }

  function decodePrice(price) {
    return price.replace("R$", "").replace(".", "").replace(",", ".");
  }

  function decodeMaterialsPrice(materials) {
    return materials.map((m) => {
      const newPrice = m.price
        .replace("R$", "")
        .replace(".", "")
        .replace(",", ".");
      return { ...m, price: Number(newPrice), quantity: Number(m.quantity) };
    });
  }

  function calculateBid(materials) {
    const materialsPrices = materials.map((m) => {
      return m.quantity * m.price;
    });

    return materialsPrices.reduce((acumulador, elemento) => {
      return acumulador + elemento;
    }, 0);
  }

  async function createOffer(sucataId) {
    const body = {
      sucateiro_id: meetalUser.meetal_user_id,
      sucata_id: sucataId,
      current_bid: Number(decodePrice(currentBid)),
      materials: decodeMaterialsPrice(materialsOffer),
    };
    const result = await postOffer(data.idToken.jwtToken, body, logOut);
    console.log(result);
    if (result.data.body === "Oferta criada") {
      toast({
        title: "Oferta enviada com sucesso",
        description: "Sua oferta foi enviada com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      fetchData();
      onClose();
    } else {
      toast({
        title: "Erro ao fazer oferta",
        description: "Ocorreu um erro ao enviar oferta, tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonMakeOfferIsLoading(false);
  }

  function handleCreateOffer() {
    setButtonMakeOfferIsLoading(true);
    const offerIsCorrect = validateOffer(
      Number(chosenScrap.current_bid),
      chosenScrap.n_offers
    );
    if (offerIsCorrect) {
      createOffer(chosenScrap.sucata_id);
    } else {
      setButtonMakeOfferIsLoading(false);
    }
  }

  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  function handleMaterialsOffers(index, { name, value }) {
    const formattedValue = formatPrice(value);

    const updatedMaterials = materialsOffer.map((material, i) => {
      if (i === index) {
        return { ...material, [name]: formattedValue };
      }
      return material;
    });

    const decodedMaterials = decodeMaterialsPrice(updatedMaterials);

    const bid = calculateBid(decodedMaterials);
    const formattedBid = formatPrice(String(bid.toFixed(2)));
    setCurrentBid(formattedBid);

    setMaterialsOffer(updatedMaterials);
  }

  function createMaterialsOffer({ materials, n_offers }) {
    let materialsCopy = materials;
    if (n_offers > 0) {
      materialsCopy = materials.map((m) => {
        return { ...m, price: (m.price * 1.02).toFixed(2) };
      });
    }
    const bid = formatPrice(
      String(Number(calculateBid(materialsCopy)).toFixed(2))
    );
    setCurrentBid(bid);
    setMinimumOffer(bid);

    if (n_offers > 0) {
      materialsCopy = materials.map((m) => {
        return {
          ...m,
          price: formatPrice(String((m.price * 1.02).toFixed(2))),
        };
      });
    } else {
      materialsCopy = materials.map((m) => {
        return {
          ...m,
          price: formatPrice(String(Number(m.price).toFixed(2))),
        };
      });
    }
    setMaterialsOffer(materialsCopy);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Marketplace</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : scrapPosts.length > 0 ? (
              <SimpleGrid margin="25px" minChildWidth="290px">
                {scrapPosts.map((p) => (
                  <CardSucateiro
                    key={p.sucata_id}
                    location={p.location}
                    image={p.scrapImages[0]}
                    expiration_timestamp={p.expiration_timestamp}
                    title={p.title}
                    current_bid={formatPrice(
                      String(Number(p.current_bid).toFixed(2))
                    )}
                    total_weight={p.total_weight}
                    onClick={() => {
                      setChosenScrap(p);
                      createMaterialsOffer(p);
                      onOpen();
                    }}
                  />
                ))}
              </SimpleGrid>
            ) : (
              <EmptyState
                titleText="No momento, não há anúncios de sucata em sua região."
                descriptionText="Fique atento para fazer ofertas assim que surgirem novas oportunidades"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
      <MakeOfferDrawer
        isOpen={isOpen}
        onClose={onClose}
        chosenScrap={chosenScrap}
        currentValue={formatPrice(
          String(Number(chosenScrap.current_bid).toFixed(2))
        )}
        toggler={toggler}
        setToggler={setToggler}
        materialsOffer={materialsOffer}
        minimumOffer={minimumOffer}
        handleMaterialsOffers={handleMaterialsOffers}
        currentBid={currentBid}
        onClick={handleCreateOffer}
        buttonMakeOfferIsLoading={buttonMakeOfferIsLoading}
      />
      <FsLightbox
        toggler={toggler}
        sources={chosenScrap.scrapImages}
        types={[...new Array(3).fill("image")]}
      />
    </>
  );
}
