import { useContext, useEffect, useState } from "react";
import { HStack, VStack } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import { getContracts } from "../../services/Contracts";
import { ContractCard } from "../../components/AdminComponents";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import { useNavigate } from "react-router-dom";

export default function SucateiroMyContracts() {
  const [contracts, setContracts] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    const response = await getContracts(
      data.idToken.jwtToken,
      meetalUser.meetal_user_id,
      meetalUser.user_type,
      logOut
    );
    setPageLoading(false);
    // console.log(response);
    setContracts(response);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Meus contratos</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : contracts.length > 0 ? (
              <VStack>
                {contracts.map((c) => (
                  <ContractCard
                    key={c.contract_id}
                    images={c.scrapImages}
                    id={c.contract_id}
                    user_type={meetalUser.user_type}
                    cnpj_nome={c.industria_cnpj_nome}
                    scrap_info={c.scrap_info}
                    value={c.valor_pago}
                    status={c.contract_status}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyState
                titleText="Os seus contratos irão aparecer aqui"
                descriptionText="Os contratos são gerados automaticamente após algum fornecedor aceitar uma oferta sua"
                onClick={() => navigate("/admin/sucateiro/anuncios")}
                buttonText="Faça novas ofertas"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
