import { FormControl, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import CentralizedContainer from "../../components/Global/CentralizedContainer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import { useNavigate } from "react-router-dom";
import { CognitoUserContext } from "../../contexts/CognitoUser";
import { getUser } from "../../services/User";
import LogoWithName from "../../components/Global/LogoWithName";
import {
  SessionButton,
  SessionFormLabel,
  SessionHeading,
  SessionInput,
  SessionTextWithLink,
} from "../../components/SessionComponents";
import UserPool from "../../services/UserPool";

export default function LogIn() {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [inputEmailIsInvalid, setInputEmailIsInvalid] = useState(false);
  const [inputPasswordIsInvalid, setInputPasswordIsInvalid] = useState(false);
  const [inputEmailErrorMessage, setInputEmailErrorMessage] = useState("");
  const [inputPasswordErrorMessage, setInputPasswordErrorMessage] =
    useState("");

  const navigate = useNavigate();
  const toast = useToast();

  const regexEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]/i;

  const { authenticate } = useContext(CognitoSessionContext);
  const { setUserData } = useContext(CognitoUserContext);

  useEffect(() => {
    setInputEmailIsInvalid(false);
  }, [inputEmail]);

  useEffect(() => {
    setInputPasswordIsInvalid(false);
  }, [inputPassword]);

  function validateInputs() {
    if (inputEmail === "") {
      setInputEmailIsInvalid(true);
      setInputEmailErrorMessage("Digite um e-mail cadastrado");
      return false;
    } else if (!regexEmail.test(inputEmail)) {
      setInputEmailIsInvalid(true);
      setInputEmailErrorMessage("Digite um email válido");
      return false;
    }
    if (inputPassword === "") {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage("Campo obrigatório, digite sua senha");
      return false;
    }
    return true;
  }

  function logIn(e) {
    e.preventDefault();
    setInputIsDisabled(true);
    setButtonIsLoading(true);

    const formsCorrect = validateInputs();
    if (formsCorrect) {
      authenticate(inputEmail, inputPassword)
        .then(async (data) => {
          console.log("Logged in!", data);
          setUserData(data);
          window.localStorage.setItem("CognitoUser", JSON.stringify(data));
          const meetalUser = await getUser(
            "/user_metadata",
            data.idToken.jwtToken,
            inputEmail
          );
          console.log(meetalUser);
          if (meetalUser.data["body-json"].statusCode !== 200) {
            const user = UserPool.getCurrentUser();
            if (user) user.signOut();
            window.localStorage.removeItem("CognitoUser");
            window.localStorage.removeItem("MeetalUser");
            navigate("/entrar/em-analise");
          } else if (
            meetalUser.data["body-json"].user_metadata.user_type === "Industria"
          ) {
            navigate("/admin/industria/meus-anuncios");
          } else {
            navigate("/admin/sucateiro/anuncios");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "Incorrect username or password.") {
            toast({
              title: "Usuário ou senha incorretos",
              description: "Verifique e tente novamente",
              status: "error",
              position: "bottom-right",
              isClosable: true,
            });
          } else if (err.message === "User is not confirmed.") {
            toast({
              title: "Usuário não confirmado",
              description: "Por favor, verifique o seu e-mail para a ativação",
              status: "warning",
              position: "bottom-right",
              isClosable: true,
            });
          }
          setInputIsDisabled(false);
          setButtonIsLoading(false);
        });
    } else {
      setInputIsDisabled(false);
      setButtonIsLoading(false);
    }
  }

  return (
    <>
      <CentralizedContainer>
        <LogoWithName />
        <SessionHeading>Entrar na sua conta Meetal</SessionHeading>
        <FormControl onSubmit={logIn} as={"form"}>
          <FormControl isInvalid={true}>
            <SessionFormLabel>Seu e-mail</SessionFormLabel>
            <SessionInput
              placeholder="Digite o seu e-mail"
              type="text"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              autoFocus={true}
              disabled={inputIsDisabled}
              isInvalid={inputEmailIsInvalid}
              errorMessage={inputEmailErrorMessage}
            />
          </FormControl>
          <FormControl isInvalid={true}>
            <SessionFormLabel>Senha</SessionFormLabel>
            <SessionInput
              placeholder="Senha de acesso"
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              disabled={inputIsDisabled}
              isInvalid={inputPasswordIsInvalid}
              errorMessage={inputPasswordErrorMessage}
            />
          </FormControl>
          <SessionButton
            type="submit"
            isLoading={buttonIsLoading}
            hasMargin={true}
          >
            Continuar
          </SessionButton>
        </FormControl>
        <SessionTextWithLink
          linkText="Esqueceu a senha?"
          href="/entrar/esqueci-senha"
        />
        <SessionTextWithLink
          text="Não tem conta? "
          linkText="Clique aqui para criar"
          href="/cadastro"
        />
      </CentralizedContainer>
    </>
  );
}
