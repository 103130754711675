import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function getCollections(idToken, user_id, user_type, logOut) {
  const url = `https://3h0r9dqo30.execute-api.sa-east-1.amazonaws.com/dev/coletas?user_id=${user_id}&user_type=${user_type}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function postScheduleCollection(idToken, body, logOut) {
  const url = `https://3h0r9dqo30.execute-api.sa-east-1.amazonaws.com/dev/agendar-coleta`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function postFinishCollection(idToken, body) {
  const url = `https://3h0r9dqo30.execute-api.sa-east-1.amazonaws.com/dev/pagar-coleta`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  return await axios.post(url, JsonBody, config);
}
