import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postOffer(idToken, body, logOut) {
  const url = `https://nzqtf3nwea.execute-api.sa-east-1.amazonaws.com/dev/offers-sucateiro`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getOfferFromIndustria(idToken, sucata_id, logOut) {
  const url = `https://nzqtf3nwea.execute-api.sa-east-1.amazonaws.com/dev/offers-industria?sucata_id=${sucata_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function postAcceptOffer(idToken, body, logOut) {
  const url = `https://nzqtf3nwea.execute-api.sa-east-1.amazonaws.com/dev/offers-industria`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}
