import { useContext, useEffect, useState } from "react";
import { HStack, Icon, Switch, useToast, VStack } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import {
  PageDivider,
  WhiteContainer,
  WhiteContainerHeading,
  WhiteContainerSubHeading,
  WhiteContainerText,
} from "../../components/AdminComponents";
import { SessionButton } from "../../components/SessionComponents";
import { BsWhatsapp } from "react-icons/bs";
import { FiBell } from "react-icons/fi";
import {
  NumberInputComponent,
  SwitchLabel,
} from "../../components/GlobalComponents";
import { updateUser } from "../../services/User";

export default function IndustriaSettings() {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const toast = useToast();

  const regexPhoneNumber =
    /^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}\-?[0-9]{4}$/;

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  const [settings, setSettings] = useState({
    user_email: meetalUser.user_email,
    celular: meetalUser.celular,
    notifications_config: {
      agg_enabled: meetalUser.notifications_config
        ? meetalUser.notifications_config.agg_enabled
        : false,
      event_based_enabled: meetalUser.notifications_config
        ? meetalUser.notifications_config.event_based_enabled
        : true,
    },
  });

  function compareChanges() {
    if (!meetalUser.notifications_config) {
      setButtonDisabled(false);
    } else if (settings.celular !== meetalUser.celular) {
      setButtonDisabled(false);
    } else if (
      settings.notifications_config.agg_enabled !==
        meetalUser.notifications_config.agg_enabled ||
      settings.notifications_config.event_based_enabled !==
        meetalUser.notifications_config.event_based_enabled
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  useEffect(() => {
    compareChanges();
  }, [settings]);

  function handleChange(target) {
    const { name, value, checked } = target;

    if (name !== "agg_enabled" && name !== "event_based_enabled") {
      setSettings({
        ...settings,
        [name]: value,
      });
    } else {
      setSettings({
        ...settings,
        notifications_config: {
          ...settings.notifications_config,
          [name]: checked,
        },
      });
    }
  }

  function validatePhoneNumber() {
    if (!settings.celular) {
      toast({
        title: "Erro ao salvar alterações",
        description: "O campo do número de telefone não pode estar vazio",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }
    if (!regexPhoneNumber.test(settings.celular)) {
      toast({
        title: "Erro ao salvar alterações",
        description:
          "O número de telefone precisar conter 11 digitos, contando com o DDD",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  async function saveChanges() {
    setButtonLoading(true);
    const validatedPhoneNumber = validatePhoneNumber();
    if (validatedPhoneNumber) {
      const response = await updateUser(
        data.idToken.jwtToken,
        settings,
        logOut
      );
      if (response.data.statusCode === 200) {
        toast({
          title: "Atualizado com sucesso",
          description:
            "As configurações de notificações forma atualizadas com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        setButtonDisabled(true);
      } else {
        toast({
          title: "Erro ao atualizar",
          description:
            "Ocorreu um erro ao atualizar as informações, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      }
    }
    setButtonLoading(false);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Configurações</SubHeader>
          <AdminPageContent>
            <WhiteContainer>
              <HStack justifyContent="space-between">
                <VStack alignItems="start">
                  <WhiteContainerHeading>
                    Configurações de notificação
                  </WhiteContainerHeading>
                  <WhiteContainerText>
                    Visualize e configure os detalhes de todas as notificações
                    por WhatsApp.
                  </WhiteContainerText>
                </VStack>
                <SessionButton
                  width="180px"
                  onClick={saveChanges}
                  isDisabled={buttonDisabled}
                  isLoading={buttonLoading}
                >
                  Salvar alterações
                </SessionButton>
              </HStack>
              <PageDivider />
              <HStack justifyContent="space-between">
                <VStack alignItems="start">
                  <HStack>
                    <Icon as={BsWhatsapp} boxSize={5} color="black" />
                    <WhiteContainerSubHeading>
                      WhatsApp para notificações
                    </WhiteContainerSubHeading>
                  </HStack>
                  <WhiteContainerText>
                    Informe o número onde deseja receber as notificações.
                  </WhiteContainerText>
                </VStack>
                <VStack alignItems="start" gap="0">
                  <WhiteContainerText isLabel={true}>
                    Número de WhatsApp
                  </WhiteContainerText>
                  <NumberInputComponent
                    name="celular"
                    value={settings.celular}
                    onChange={(e) => {
                      const target = {
                        name: "celular",
                        value: e,
                      };
                      handleChange(target);
                    }}
                    isDisabled={buttonLoading}
                    placeholder="Insira o seu número de WhatsApp"
                  />
                </VStack>
              </HStack>
              <PageDivider />
              <HStack justifyContent="space-between">
                <VStack alignItems="start">
                  <HStack>
                    <Icon as={FiBell} boxSize={5} color="black" />
                    <WhiteContainerSubHeading>
                      Consolidado de coletas e pagamentos da semana
                    </WhiteContainerSubHeading>
                  </HStack>
                  <WhiteContainerText>
                    Receba o consolidado de coletas em andamento e pagamentos
                    recebidos na semana anterior.
                  </WhiteContainerText>
                </VStack>
                <HStack gap="15px">
                  <SwitchLabel>
                    {settings.notifications_config.agg_enabled
                      ? "Ativado"
                      : "Desativado"}
                  </SwitchLabel>
                  <Switch
                    defaultChecked={settings.notifications_config.agg_enabled}
                    name="agg_enabled"
                    value={settings.notifications_config.agg_enabled}
                    onChange={(e) => handleChange(e.target)}
                    isDisabled={buttonLoading}
                  />
                </HStack>
              </HStack>
              <PageDivider />
              <HStack justifyContent="space-between">
                <VStack alignItems="start">
                  <HStack>
                    <Icon as={FiBell} boxSize={5} color="black" />
                    <WhiteContainerSubHeading>
                      Ofertas recebidas
                    </WhiteContainerSubHeading>
                  </HStack>
                  <WhiteContainerText>
                    Receba notificações de ofertas recebidas ao fim de cada
                    anúncio.
                  </WhiteContainerText>
                </VStack>
                <HStack gap="15px">
                  <SwitchLabel>
                    {settings.notifications_config.event_based_enabled
                      ? "Ativado"
                      : "Desativado"}
                  </SwitchLabel>
                  <Switch
                    defaultChecked={
                      settings.notifications_config.event_based_enabled
                    }
                    name="event_based_enabled"
                    value={settings.notifications_config.event_based_enabled}
                    onChange={(e) => handleChange(e.target)}
                    isDisabled={buttonLoading}
                  />
                </HStack>
              </HStack>
            </WhiteContainer>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
