import { Navigate } from "react-router-dom";

export default function Authenticator({ children }) {
  const user = JSON.parse(window.localStorage.getItem("CognitoUser"));

  if (!user || !user.accessToken || !user.accessToken.jwtToken) {
    return <Navigate to="/entrar" />;
  }

  return <>{children}</>;
}
