import { HStack } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import SubHeader from "../../components/Global/SubHeader";
import Filter from "../../components/Global/Filter";
import { SessionInput } from "../../components/SessionComponents";
import { useState } from "react";

export default function IndustriaPageTest() {
  const [searchInput, setSearchInput] = useState("");
  const [statusFilter, setStatusFilter] = useState("Status");

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Meus anúncios</SubHeader>
          <Filter
            inputValue={searchInput}
            inputOnChange={(e) => setSearchInput(e.target.value)}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
          />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
