import { FormControl, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import Pool from "../../services/UserPool";
import CentralizedContainer from "../../components/Global/CentralizedContainer";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import LogoWithName from "../../components/Global/LogoWithName";
import {
  SessionButton,
  SessionFormLabel,
  SessionHeading,
  SessionInput,
  SessionMailIcon,
  SessionPinInputs,
  SessionText,
  SessionTextBold,
  SessionTextButton,
  SessionTooltip,
} from "../../components/SessionComponents";

export default function ForgotPassword() {
  const [inputEmail, setInputEmail] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [inputNewPassword, setInputNewPassword] = useState("");
  const [inputConfirmNewPassword, setInputConfirmNewPassword] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [codeEntered, setCodeEntered] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [tooltipIsDisabled, setTooltipIsDisabled] = useState(false);
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [inputEmailIsInvalid, setInputEmailIsInvalid] = useState(false);
  const [inputNewPasswordIsInvalid, setInputNewPasswordIsInvalid] =
    useState(false);
  const [
    inputConfirmNewPasswordIsInvalid,
    setInputConfirmNewPasswordIsInvalid,
  ] = useState(false);
  const [inputEmailErrorMessage, setInputEmailErrorMessage] = useState("");
  const [inputNewPasswordErrorMessage, setInputNewPasswordErrorMessage] =
    useState("");
  const [
    inputConfirmNewPasswordErrorMessage,
    setInputConfirmNewPasswordErrorMessage,
  ] = useState("");
  const [pinInputIsInvalid, setPinInputIsInvalid] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const regexEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]/i;
  const regexNumericCharacters = /^(?=.*[0-9])/;
  const regexSymbolCharacters = /^(?=.*[@!#$%^&*()/\\])/;
  const regexUpperCaseLetters = /^(?=.*[A-Z])/;
  const regexLowerCaseLetters = /^(?=.*[a-z])/;

  useEffect(() => {
    setInputEmailIsInvalid(false);
    if (inputEmail) {
      setButtonIsDisabled(false);
      setTooltipIsDisabled(true);
    } else {
      setButtonIsDisabled(true);
      setTooltipIsDisabled(false);
    }
  }, [inputEmail]);

  useEffect(() => {
    setInputNewPasswordIsInvalid(false);
    if (inputNewPassword) {
      setButtonIsDisabled(false);
      setTooltipIsDisabled(true);
    } else {
      setButtonIsDisabled(true);
      setTooltipIsDisabled(false);
    }
  }, [inputNewPassword]);

  useEffect(() => {
    setInputConfirmNewPasswordIsInvalid(false);
  }, [inputConfirmNewPassword]);

  function getUser() {
    return new CognitoUser({
      Username: inputEmail,
      Pool,
    });
  }

  function validateEmail() {
    if (!regexEmail.test(inputEmail)) {
      setInputEmailIsInvalid(true);
      setInputEmailErrorMessage("Digite um email válido");
      return false;
    }
    return true;
  }

  function sendCode(e) {
    e.preventDefault();

    setInputIsDisabled(true);
    setButtonIsLoading(true);

    const emailCorrect = validateEmail();
    if (emailCorrect) {
      getUser().forgotPassword({
        onSuccess: (data) => {
          console.log("data", data);
          setEmailVerified(true);
          setTooltipIsDisabled(false);
          setInputIsDisabled(false);
          setButtonIsLoading(false);
        },
        onFailure: (err) => {
          setInputIsDisabled(false);
          setButtonIsLoading(false);
          console.log("error", err);
        },
      });
    } else {
      setInputIsDisabled(false);
      setButtonIsLoading(false);
    }
  }

  function validateNewPassword() {
    if (inputNewPassword.length < 8) {
      setInputNewPasswordIsInvalid(true);
      setInputNewPasswordErrorMessage(
        "Sua senha precisa ter no mínimo 8 caracteres"
      );
      return false;
    } else if (!regexNumericCharacters.test(inputNewPassword)) {
      setInputNewPasswordIsInvalid(true);
      setInputNewPasswordErrorMessage("Sua senha precisa ter numeros");
      return false;
    } else if (!regexSymbolCharacters.test(inputNewPassword)) {
      setInputNewPasswordIsInvalid(true);
      setInputNewPasswordErrorMessage(
        "Sua senha precisa ter caracteres especiais"
      );
      return false;
    } else if (!regexUpperCaseLetters.test(inputNewPassword)) {
      setInputNewPasswordIsInvalid(true);
      setInputNewPasswordErrorMessage(
        "Sua senha precisa ter pelo menos uma letra maiúscula"
      );
      return false;
    } else if (!regexLowerCaseLetters.test(inputNewPassword)) {
      setInputNewPasswordIsInvalid(true);
      setInputNewPasswordErrorMessage(
        "Sua senha precisa ter pelo menos uma letra minúscula"
      );
      return false;
    }
    if (inputConfirmNewPassword !== inputNewPassword) {
      setInputConfirmNewPasswordIsInvalid(true);
      setInputConfirmNewPasswordErrorMessage(
        "As senhas digitadas não são iguais"
      );
      return false;
    }
    return true;
  }

  function submitCodeAndNewPassword(e) {
    e.preventDefault();

    setInputIsDisabled(true);
    setButtonIsLoading(true);

    const newPasswordCorrect = validateNewPassword();
    if (newPasswordCorrect) {
      getUser().confirmPassword(inputCode, inputNewPassword, {
        onSuccess: (data) => {
          console.log("sucess", data);
          toast({
            title: "Senha alterada com sucesso",
            status: "success",
            position: "bottom-right",
            isClosable: true,
          });
          navigate("/entrar");
        },
        onFailure: (err) => {
          console.log("error", err);
          setInputIsDisabled(false);
          setButtonIsLoading(false);

          if (
            err.message ===
            "Invalid verification code provided, please try again."
          ) {
            setPinInputIsInvalid(true);
            setCodeEntered(false);
          }
        },
      });
    } else {
      setInputIsDisabled(false);
      setButtonIsLoading(false);
    }
  }

  function resendVerificationCode() {
    getUser().resendConfirmationCode(() => {
      toast({
        title: "Código enviado com sucesso",
        description: "o código foi enviado para o seu e-mail",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    });
  }

  return (
    <>
      <CentralizedContainer>
        <LogoWithName />
        {emailVerified ? (
          codeEntered ? (
            <>
              <SessionHeading>Digite uma nova senha</SessionHeading>
              <FormControl onSubmit={submitCodeAndNewPassword} as={"form"}>
                <FormControl isInvalid={true}>
                  <SessionFormLabel>Nova senha</SessionFormLabel>
                  <SessionInput
                    placeholder="Nova senha de acesso"
                    type="password"
                    value={inputNewPassword}
                    onChange={(e) => setInputNewPassword(e.target.value)}
                    disabled={inputIsDisabled}
                    isInvalid={inputNewPasswordIsInvalid}
                    errorMessage={inputNewPasswordErrorMessage}
                  />
                </FormControl>
                <FormControl isInvalid={true}>
                  <SessionFormLabel>Repetir senha</SessionFormLabel>
                  <SessionInput
                    placeholder="Repetir nova senha de acesso"
                    type="password"
                    value={inputConfirmNewPassword}
                    onChange={(e) => setInputConfirmNewPassword(e.target.value)}
                    disabled={inputIsDisabled}
                    isInvalid={inputConfirmNewPasswordIsInvalid}
                    errorMessage={inputConfirmNewPasswordErrorMessage}
                  />
                </FormControl>
                <SessionTooltip
                  isDisabled={tooltipIsDisabled}
                  text="Insira a nova senha para continuar"
                >
                  <SessionButton
                    type="submit"
                    isDisabled={buttonIsDisabled}
                    isLoading={buttonIsLoading}
                    hasMargin={true}
                  >
                    Continuar
                  </SessionButton>
                </SessionTooltip>
              </FormControl>
            </>
          ) : (
            <>
              <SessionMailIcon />
              <SessionHeading>
                Enviamos um código para o seu e-mail
              </SessionHeading>
              <SessionText>
                Você receberá um código de verificação no seu e-mail{" "}
                <SessionTextBold>{inputEmail}</SessionTextBold>, preencha abaixo
              </SessionText>
              <SessionPinInputs
                onComplete={() => {
                  setTimeout(() => {
                    setCodeEntered(true);
                    setButtonIsDisabled(true);
                  }, 1000);
                }}
                onChange={(e) => {
                  setPinInputIsInvalid(false);
                  setInputCode(e);
                }}
                isDisabled={inputIsDisabled}
                isInvalid={pinInputIsInvalid}
              />
              <SessionText>
                Não recebeu?{" "}
                <SessionTextButton
                  onClick={resendVerificationCode}
                  isDisabled={buttonIsDisabled}
                >
                  Reenviar código
                </SessionTextButton>
              </SessionText>
            </>
          )
        ) : (
          <>
            <SessionHeading>Esqueceu a senha?</SessionHeading>
            <FormControl onSubmit={sendCode} as={"form"} isInvalid={true}>
              <SessionFormLabel>Seu e-mail</SessionFormLabel>
              <SessionInput
                placeholder="Digite o seu e-mail"
                type="text"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                autoFocus={true}
                disabled={inputIsDisabled}
                isInvalid={inputEmailIsInvalid}
                errorMessage={inputEmailErrorMessage}
              />
              <SessionTooltip
                isDisabled={tooltipIsDisabled}
                text="Insira o e-mail para resetar a sua senha"
              >
                <SessionButton
                  type="submit"
                  isDisabled={buttonIsDisabled}
                  isLoading={buttonIsLoading}
                  hasMargin={true}
                >
                  Continuar
                </SessionButton>
              </SessionTooltip>
            </FormControl>
          </>
        )}
      </CentralizedContainer>
    </>
  );
}
