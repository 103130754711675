import {
  GetObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const S3_BUCKET_PHOTOS = "sucata-files";
const S3_BUCKET_TICKET_PESAGEM = "ticket-pesagem";
const REGION = "sa-east-1";

const s3Configuration = {
  credentials: {
    accessKeyId: import.meta.env.VITE_ACCESS_KEY,
    secretAccessKey: import.meta.env.VITE_SECRET_ACCESS_KEY,
  },
  region: REGION,
};

const s3 = new S3Client(s3Configuration);

export async function dowloadAndAddPhotoToScrap(sucatas) {
  const arraySucatas = await Promise.all(
    sucatas.map(async (sucata) => {
      if (sucata.files) {
        const scrapImages = [];
        for (let i = 0; i < sucata.files.length; i++) {
          const params = {
            Bucket: S3_BUCKET_PHOTOS,
            Key: sucata.files[i],
          };
          const command = new GetObjectCommand(params);

          const scrapImage = await getSignedUrl(s3, command, {
            expiresIn: 15 * 60,
          });
          scrapImages.push(scrapImage);
        }

        return { ...sucata, scrapImages };
      }
      return { ...sucata };
    })
  );

  return arraySucatas;
}

export async function uploadFile(fileKey, image, bucket) {
  const params = {
    Bucket: bucket,
    Key: fileKey,
    Body: image,
  };

  const command = new PutObjectCommand(params);

  await s3.send(command);
}
