import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { brandColorBlue } from "../../GlobalStyles";
import LogoWithWhiteName from "./LogoWithWhiteName";
import { useNavigate } from "react-router-dom";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import QRCode from "qrcode";
import { useContext, useState } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import { handleAsaasConnect } from "../../services/Payment";
import { getUser } from "../../services/User";
import { QrCodeModal } from "../GlobalComponents";

export default function Header({ isDisabled }) {
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const { name, surname, user_type, onboarding_finished, user_email } =
    JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function checkStripeOnboarding() {
    if (!onboarding_finished) {
      await getUser("/user_metadata", data.idToken.jwtToken, user_email);
    }
  }

  checkStripeOnboarding();

  return (
    <>
      <Flex
        width="100%"
        height="80px"
        backgroundColor={brandColorBlue}
        alignItems="center"
        padding="0 1.5vw"
        position="fixed"
        top="0"
        right="0"
        zIndex="2"
      >
        <LogoWithWhiteName />
        <Spacer />
        <HStack gap="1.5vw">
          <Button
            size="lg"
            width={{ base: "160px", lg: "200px" }}
            backgroundColor="orange.500"
            color="white"
            _hover={{
              backgroundColor: "orange.600",
            }}
            _focus={{
              border: "3px solid",
              borderColor: "blue.100",
            }}
            _disabled={{
              backgroundColor: "gray.300",
              cursor: "not-allowed",
            }}
            isDisabled={isDisabled}
            isLoading={buttonLoading}
            loadingText="Carregando"
            onClick={async () => {
              if (user_type === "Industria") {
                if (onboarding_finished) {
                  navigate("/admin/industria/nova-sucata");
                } else {
                  setbuttonLoading(true);
                  toast({
                    title: "Atenção",
                    description:
                      "Essa ação pode demorar alguns segundos, aguarde por favor",
                    status: "info",
                    position: "bottom-right",
                    isClosable: true,
                  });
                  const url = await handleAsaasConnect();
                  setQrCode(await QRCode.toDataURL(url));
                  onOpen();
                  setbuttonLoading(false);
                }
              } else {
                navigate("/admin/sucateiro/minhas-ordens");
              }
            }}
          >
            {user_type === "Industria"
              ? onboarding_finished
                ? "Criar anúncio"
                : "Configurar Pagamento"
              : "Agendar coleta"}
          </Button>
          <HStack>
            <Avatar
              size="md"
              name={name + " " + surname}
              backgroundColor="gray.600"
              color="white"
            >
              <AvatarBadge
                boxSize="20px"
                bg="green.300"
                border="3px solid"
                borderColor={brandColorBlue}
              />
            </Avatar>
            <Menu>
              <MenuButton
                as={Button}
                size="lg"
                fontWeight="400"
                variant="ghost"
                color="white"
                padding="0"
                rightIcon={<FiChevronDown />}
                _hover={{
                  backgroundColor: "none",
                }}
                _active={{
                  backgroundColor: "none",
                }}
              >
                {name + " " + surname}
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<FiLogOut />}
                  color="gray.800"
                  _hover={{
                    backgroundColor: "gray.200",
                  }}
                  onClick={logOut}
                >
                  Sair
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </Flex>
      <QrCodeModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Configuração do pagamento"
        qrCode={qrCode}
        bodyText="Aponte a câmera do seu celular e siga as instruções"
        refreshButtonOnClick={async () => {
          const response = await getUser(
            "/user_metadata",
            data.idToken.jwtToken,
            user_email
          );
          window.localStorage.setItem(
            "MeetalUser",
            JSON.stringify(response.data["body-json"].user_metadata)
          );
          onClose();
        }}
        refreshButtonText="Fechar"
      />
    </>
  );
}
