import { Flex } from "@chakra-ui/react";

export default function AdminPageContainer({ children }) {
  return (
    <Flex
      width="100vw"
      minHeight="calc(100vh - 80px)"
      padding={{ base: "75px 0 80px 200px", lg: "85px 0 80px 240px" }}
      flexDirection="column"
      backgroundColor="gray.50"
      position="relative"
      top="80px"
      zIndex="1"
    >
      {children}
    </Flex>
  );
}
