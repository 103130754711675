import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

export default function FilterSelect({
  placeholder,
  options,
  setFilter,
  filter,
}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="md"
        fontWeight="400"
        backgroundColor="white"
        color="gray.700"
        border="1px solid"
        borderColor="gray.200"
        rightIcon={<FiChevronDown />}
        _hover={{
          backgroundColor: "none",
        }}
        _active={{
          backgroundColor: "none",
        }}
      >
        {filter}
      </MenuButton>
      <MenuList padding="0">
        <MenuItem
          color="gray.800"
          _hover={{
            backgroundColor: "gray.200",
          }}
          onClick={() => setFilter(placeholder)}
        >
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option}
            color="gray.800"
            _hover={{
              backgroundColor: "gray.200",
            }}
            onClick={() => setFilter(option)}
          >
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
