import CentralizedContainer from "../../components/Global/CentralizedContainer";
import LogoWithName from "../../components/Global/LogoWithName";
import {
  SessionClockIcon,
  SessionHeading,
  SessionText,
} from "../../components/SessionComponents";

export default function UnderReview() {
  return (
    <CentralizedContainer>
      <LogoWithName />
      <SessionClockIcon />
      <SessionHeading>
        Olá! Sua solicitação ainda está em análise.
      </SessionHeading>
      <SessionText>
        Você receberá em breve um retorno no e-mail cadastrado mais rápido
        possível.
      </SessionText>
    </CentralizedContainer>
  );
}
