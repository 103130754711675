import { Container, Text } from "@chakra-ui/react";
import { brandColorBlue } from "../../GlobalStyles";
import { Oval } from "react-loader-spinner";

export default function LoadingState() {
  return (
    <Container
      maxWidth="none"
      marginTop="150px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="15px"
    >
      <Oval
        width="60"
        height="60"
        strokeWidth={4}
        color={brandColorBlue}
        secondaryColor="#CBD5E0"
      />
      <Text fontSize="md" fontWeight="500" color="gray.400">
        Carregando
      </Text>
    </Container>
  );
}
