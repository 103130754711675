import { Button, Flex, HStack, Text } from "@chakra-ui/react";
import GrayLogoWithName from "./GrayLogoWithName";
import { FiExternalLink } from "react-icons/fi";

export default function Footer() {
  return (
    <Flex
      width={{ base: "calc(100vw - 200px)", lg: "calc(100vw - 240px)" }}
      height="80px"
      backgroundColor="transparent"
      justifyContent="space-between"
      alignItems="center"
      padding="0 1.5vw"
      position="absolute"
      bottom="0"
      right="0"
      zIndex="-1"
    >
      <GrayLogoWithName />
      <Text fontSize="md" color="gray.400">
        © 2024 Meetal. Todos os Direitos Reservados.
      </Text>
      <HStack>
        <Button
          size="sm"
          fontWeight="600"
          variant="link"
          color="gray.500"
          _hover={{
            color: "gray.800",
            textDecoration: "underline",
          }}
          rightIcon={<FiExternalLink />}
          onClick={() => {
            window.open("https://meetal.com.br/termosdeuso", "_blank");
          }}
        >
          Termos de uso
        </Button>
        <Button
          size="sm"
          fontWeight="600"
          variant="link"
          color="gray.500"
          _hover={{
            color: "gray.800",
            textDecoration: "underline",
          }}
          rightIcon={<FiExternalLink />}
          onClick={() => {
            window.open(
              "https://meetal.com.br/politicadeprivacidade",
              "_blank"
            );
          }}
        >
          Politica de privacidade
        </Button>
      </HStack>
    </Flex>
  );
}
