import { Container } from "@chakra-ui/react";

export default function CentralizedContainer({ children }) {
  return (
    <Container
      maxWidth="none"
      width={{ base: "100%", md: "720px" }}
      paddingBottom="40px"
      marginTop="60px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {children}
    </Container>
  );
}
