import { createContext, useState } from "react";

export const CognitoUserContext = createContext({});

export default function CognitoUserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  return (
    <CognitoUserContext.Provider
      value={{
        userData,
        setUserData,
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
      }}
    >
      {children}
    </CognitoUserContext.Provider>
  );
}
