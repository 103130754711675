import { postFinishCollection } from "./Collections";
import axios from "axios";

async function asaasOnboarding(idToken, body) {
  const url = `https://099qib60w5.execute-api.sa-east-1.amazonaws.com/dev/asaas-onboarding?`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const jsonBody = JSON.stringify(body);

  return await axios.post(url, jsonBody, config);
}

export async function handlePayment(
  currentPrice,
  contract_id,
  coleta_id,
  industria_apiKey,
  email_industria,
  ticketPesagem,
  currentWeight,
  formattedMaterials
) {
  const {
    ["Nº CNPJ da Matriz"]: cnpj,
    name,
    user_email,
  } = JSON.parse(window.localStorage.getItem("MeetalUser"));
  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));

  try {
    const body = {
      user_cnpj: cnpj,
      user_name: name,
      user_email,
      contract_id,
      coleta_id,
      email_industria,
      industria_apiKey,
      valor_coleta: Number(currentPrice),
      ticket_pesagem: ticketPesagem,
      total_weight: currentWeight,
      materials_weight: formattedMaterials,
    };

    const response = await postFinishCollection(
      cognitoUser.idToken.jwtToken,
      body
    );

    window.open(response.data.payment_url, "_blank");
    return response;
  } catch (error) {
    console.error("Asaas error:", error);
    // Handle error (e.g., display error message to user)
  }
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export async function handleAsaasConnect() {
  const {
    user_email,
    name,
    surname,
    ["Nº CNPJ da Matriz"]: cnpj,
    celular,
    Rua,
    ["Número"]: addressNumber,
    Bairro,
    ["CEP da unidade"]: cep,
    faturamento_mensal,
    tipo_empresa,
  } = JSON.parse(window.localStorage.getItem("MeetalUser"));
  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  try {
    const body = {
      name: `${name} ${surname}`,
      email: user_email,
      cpfCnpj: cnpj,
      phone: celular,
      mobilePhone: celular,
      companyType: tipo_empresa,
      incomeValue: faturamento_mensal,
      address: Rua,
      addressNumber: isNumeric(addressNumber) ? Number(addressNumber) : 0,
      province: Bairro,
      postalCode: cep,
      webhooks: [
        {
          name: "Webhook para cobranças",
          url: "https://qqokdqm096.execute-api.sa-east-1.amazonaws.com/dev/asaas-webhooks",
          email: user_email,
          sendType: "SEQUENTIALLY",
          interrupted: false,
          enabled: true,
          apiVersion: 3,
          authToken: "5tLxsL6uoN",
          events: [
            "ACCOUNT_STATUS_GENERAL_APPROVAL_APPROVED",
            "PAYMENT_CONFIRMED",
            "PAYMENT_RECEIVED",
          ],
        },
      ],
    };

    const response = await asaasOnboarding(cognitoUser.idToken.jwtToken, body);
    const url = await response.data.onboardingUrl;
    return url;
  } catch (error) {
    console.error("Asaas error:", error);
    // Handle error (e.g., display error message to user)
  }
}
