import { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../services/UserPool";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export const CognitoSessionContext = createContext({});

export default function CognitoSessionProvider(props) {
  const navigate = useNavigate();
  const toast = useToast();

  async function getSession() {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  }

  async function authenticate(Username, Password) {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSucess:", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.log("onFailure:", err);
          reject(err);
        },
      });
    });
  }

  function logOut(sessionExpired) {
    const user = Pool.getCurrentUser();
    if (sessionExpired === true) {
      const toastId = "sessionExpiredToastId";
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: "Sua sessão expirou",
          description:
            "Sua sessão não está mais ativa, você será redirecionado para a tela de log-in",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      }
      if (user) user.signOut();
      window.localStorage.removeItem("CognitoUser");
      window.localStorage.removeItem("MeetalUser");
      navigate("/entrar");
    } else {
      if (user) user.signOut();
      window.localStorage.removeItem("CognitoUser");
      window.localStorage.removeItem("MeetalUser");
      navigate("/entrar");
    }
  }

  return (
    <CognitoSessionContext.Provider
      value={{ authenticate, getSession, logOut }}
    >
      {props.children}
    </CognitoSessionContext.Provider>
  );
}
