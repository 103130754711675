import { FormControl, useToast } from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import UserPool from "../../services/UserPool";
import CentralizedContainer from "../../components/Global/CentralizedContainer";
import { CognitoUserContext } from "../../contexts/CognitoUser";
import { useNavigate } from "react-router-dom";
import LogoWithName from "../../components/Global/LogoWithName";
import {
  SessionHeading,
  SessionFormLabel,
  SessionInput,
  SessionCheckBox,
  SessionButton,
  SessionTooltip,
  SessionTextWithLink,
  SessionFormHelperText,
  SessionPopover,
} from "../../components/SessionComponents";

export default function SignUp() {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputConfirmPassword, setInputConfirmPassword] = useState("");
  const [inputCheckBox, setInputCheckBox] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [tooltipIsDisabled, setTooltipIsDisabled] = useState(false);
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [inputEmailIsInvalid, setInputEmailIsInvalid] = useState(false);
  const [inputPasswordIsInvalid, setInputPasswordIsInvalid] = useState(false);
  const [inputConfirmPasswordIsInvalid, setInputConfirmPasswordIsInvalid] =
    useState(false);
  const [inputEmailErrorMessage, setInputEmailErrorMessage] = useState("");
  const [inputPasswordErrorMessage, setInputPasswordErrorMessage] =
    useState("");
  const [
    inputConfirmPasswordErrorMessage,
    setInputConfirmPasswordErrorMessage,
  ] = useState("");

  const navigate = useNavigate();
  const toast = useToast();

  const regexEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]/i;
  const regexNumericCharacters = /^(?=.*[0-9])/;
  const regexSymbolCharacters = /^(?=.*[@!#$%^&*()/\\])/;
  const regexUpperCaseLetters = /^(?=.*[A-Z])/;
  const regexLowerCaseLetters = /^(?=.*[a-z])/;

  const { setUserData, setUserEmail, setUserPassword } =
    useContext(CognitoUserContext);

  useEffect(() => {
    validateTermsAndConditions();
  }, [inputCheckBox]);

  useEffect(() => {
    setInputEmailIsInvalid(false);
  }, [inputEmail]);

  useEffect(() => {
    setInputPasswordIsInvalid(false);
  }, [inputPassword]);

  useEffect(() => {
    setInputConfirmPasswordIsInvalid(false);
  }, [inputConfirmPassword]);

  function validateTermsAndConditions() {
    if (inputCheckBox) {
      setButtonIsDisabled(false);
      setTooltipIsDisabled(true);
    } else {
      setButtonIsDisabled(true);
      setTooltipIsDisabled(false);
    }
  }

  function validateInputs() {
    if (inputEmail === "") {
      setInputEmailIsInvalid(true);
      setInputEmailErrorMessage("Campo obrigatório, digite o seu e-mail");
      return false;
    } else if (!regexEmail.test(inputEmail)) {
      setInputEmailIsInvalid(true);
      setInputEmailErrorMessage("Digite um email válido");
      return false;
    }
    if (inputPassword === "") {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage("Campo obrigatório, digite a sua senha ");
      return false;
    } else if (inputPassword.length < 8) {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage(
        "Sua senha precisa ter no mínimo 8 caracteres"
      );
      return false;
    } else if (!regexNumericCharacters.test(inputPassword)) {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage("Sua senha precisa ter numeros");
      return false;
    } else if (!regexSymbolCharacters.test(inputPassword)) {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage(
        "Sua senha precisa ter caracteres especiais"
      );
      return false;
    } else if (!regexUpperCaseLetters.test(inputPassword)) {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage(
        "Sua senha precisa ter pelo menos uma letra maiúscula"
      );
      return false;
    } else if (!regexLowerCaseLetters.test(inputPassword)) {
      setInputPasswordIsInvalid(true);
      setInputPasswordErrorMessage(
        "Sua senha precisa ter pelo menos uma letra minúscula"
      );
      return false;
    }
    if (inputConfirmPassword !== inputPassword) {
      setInputConfirmPasswordIsInvalid(true);
      setInputConfirmPasswordErrorMessage("As senhas digitadas não são iguais");
      return false;
    }
    return true;
  }

  function submitSignInData(e) {
    e.preventDefault();
    setInputIsDisabled(true);
    setButtonIsLoading(true);

    const formsCorrect = validateInputs();
    if (formsCorrect) {
      UserPool.signUp(inputEmail, inputPassword, null, null, (err, data) => {
        if (err) {
          console.log(err);
          if (err.message === "User already exists") {
            toast({
              title: "Este e-mail já está cadastrado",
              description: "Insira outro e-mail para criar a sua conta ",
              status: "error",
              position: "bottom-right",
              isClosable: true,
            });
          }
          setInputIsDisabled(false);
          setButtonIsLoading(false);
        } else {
          console.log(data);
          setUserData(data.user);
          setUserEmail(inputEmail);
          setUserPassword(inputPassword);
          navigate("/cadastro/verificar-conta");
        }
      });
    } else {
      setInputIsDisabled(false);
      setButtonIsLoading(false);
    }
  }

  return (
    <>
      <CentralizedContainer>
        <LogoWithName />
        <SessionHeading>Criar conta</SessionHeading>
        <FormControl onSubmit={submitSignInData} as={"form"}>
          <FormControl isInvalid={true}>
            <SessionFormLabel>Seu e-mail</SessionFormLabel>
            <SessionInput
              placeholder="Digite o seu e-mail"
              type="text"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              autoFocus={true}
              disabled={inputIsDisabled}
              isInvalid={inputEmailIsInvalid}
              errorMessage={inputEmailErrorMessage}
            />
            {inputEmailIsInvalid ? (
              <></>
            ) : (
              <SessionFormHelperText>
                Utilize o mesmo e-mail enviado no formulário.
              </SessionFormHelperText>
            )}
          </FormControl>
          <FormControl isInvalid={true}>
            <SessionFormLabel>Senha</SessionFormLabel>
            <SessionPopover>
              <SessionInput
                placeholder="Senha de acesso"
                type="password"
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                disabled={inputIsDisabled}
                isInvalid={inputPasswordIsInvalid}
                errorMessage={inputPasswordErrorMessage}
              />
            </SessionPopover>
          </FormControl>
          <FormControl isInvalid={true}>
            <SessionFormLabel>Repetir senha</SessionFormLabel>
            <SessionInput
              placeholder="Repetir senha de acesso"
              type="password"
              value={inputConfirmPassword}
              onChange={(e) => setInputConfirmPassword(e.target.value)}
              disabled={inputIsDisabled}
              isInvalid={inputConfirmPasswordIsInvalid}
              errorMessage={inputConfirmPasswordErrorMessage}
            />
          </FormControl>
          <SessionCheckBox
            onChange={(e) => setInputCheckBox(e.target.checked)}
            disabled={inputIsDisabled}
          >
            Estou de acordo com os Termos de Uso e a Política de Privacidade da
            plataforma.
          </SessionCheckBox>
          <SessionTooltip
            isDisabled={tooltipIsDisabled}
            text="Aceite os termos para continuar"
          >
            <SessionButton
              type="submit"
              isDisabled={buttonIsDisabled}
              isLoading={buttonIsLoading}
              hasMargin={true}
            >
              Continuar
            </SessionButton>
          </SessionTooltip>
        </FormControl>
        <SessionTextWithLink
          text="Já possui uma conta? "
          linkText="Clique aqui para acessar"
          href="/entrar"
        />
      </CentralizedContainer>
    </>
  );
}
