import { useContext, useEffect, useState } from "react";
import { HStack, VStack, useToast } from "@chakra-ui/react";
import { deleteSucata, getSucatas } from "../../services/Sucatas";
import { getOfferFromIndustria, postAcceptOffer } from "../../services/Offers";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import {
  AdminModal,
  OffersDrawer,
  ScrapCard,
} from "../../components/AdminComponents";
import { useDisclosure } from "@chakra-ui/react";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import { useNavigate } from "react-router-dom";

export default function IndustriaMyScrapPosts() {
  const [scrapPosts, setScrapPosts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [chosenOfferId, setChosenOfferId] = useState("");
  const [chosenScrap, setChosenScrap] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const {
    isOpen: drawerIsOpen,
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
  } = useDisclosure();
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const toast = useToast();

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    const sucatas = await getSucatas(
      data.idToken.jwtToken,
      meetalUser.meetal_user_id,
      logOut
    );
    setPageLoading(false);
    setScrapPosts(sucatas);
  }

  // setInterval(() => {
  //   fetchData();
  // }, 60000);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chosenOfferId) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [chosenOfferId]);

  function deleteScrapPost() {
    const promise = deleteSucata(
      data.idToken.jwtToken,
      chosenScrap.sucata_id,
      logOut
    );

    toast.promise(promise, {
      success: {
        title: "Excluído com sucesso",
        description: `O anúncio ${
          chosenScrap.sucata_id.split("-")[0]
        } foi excluído com sucesso`,
        position: "bottom-right",
        isClosable: true,
      },
      error: {
        title: "Erro ao excluir",
        description: `Não foi possível excluir o anúncio ${
          chosenScrap.sucata_id.split("-")[0]
        }`,
        position: "bottom-right",
        isClosable: true,
      },
      loading: {
        title: "Excluindo o anúncio",
        description: "Por favor aguarde",
        position: "bottom-right",
        isClosable: true,
      },
    });
    promise.then(() => {
      fetchData();
    });
  }

  async function acceptOffer() {
    setButtonLoading(true);

    const chosenOfferObject = offers.filter(
      (o) => o.oferta_id === chosenOfferId
    );
    const chosenScrapObject = scrapPosts.filter(
      (s) => s.sucata_id === chosenOfferObject[0].sucata_id
    );
    const scrapInfo = {
      branch: chosenScrapObject[0].branch,
      location: chosenScrapObject[0].location,
      has_scale: chosenScrapObject[0].has_scale,
      scale_capacity: chosenScrapObject[0].scale_capacity,
      site_equipment: chosenScrapObject[0].site_equipment,
      equipment: chosenScrapObject[0].equipment,
      extra_info: chosenScrapObject[0].extra_info,
    };
    const body = {
      industria_id: meetalUser.meetal_user_id,
      sucateiro_id: chosenOfferObject[0].sucateiro_id,
      sucata_id: chosenOfferObject[0].sucata_id,
      oferta_id: chosenOfferObject[0].oferta_id,
      current_bid: chosenOfferObject[0].current_bid,
      files: chosenScrapObject[0].files,
      scrap_info: scrapInfo,
    };
    const response = await postAcceptOffer(data.idToken.jwtToken, body, logOut);
    console.log(response);
    if (response.data.body === "Contrato criado") {
      toast({
        title: "Contrato criado com sucesso",
        description: "Após o aceite da oferta um contrato foi criado.",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      drawerOnClose();
      fetchData();
      setButtonLoading(false);
    } else {
      toast({
        title: "Erro ao aceitar oferta",
        description: "Ocorreu um erro ao aceitar a oferta, tente novamente ",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      fetchData();
      setButtonLoading(false);
    }
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Meus anúncios</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : scrapPosts.length > 0 ? (
              <VStack>
                {scrapPosts.map((p) => (
                  <ScrapCard
                    key={p.sucata_id}
                    images={p.scrapImages}
                    id={p.sucata_id}
                    status={p.sucata_status}
                    title={p.title}
                    branch={p.branch}
                    expiration_timestamp={p.expiration_timestamp}
                    buttonOffers={async () => {
                      const response = await getOfferFromIndustria(
                        data.idToken.jwtToken,
                        p.sucata_id,
                        logOut
                      );
                      drawerOnOpen();
                      setChosenScrap(p);
                      setChosenOfferId("");
                      setOffers(JSON.parse(response.data.body));
                    }}
                    buttonDelete={async () => {
                      setChosenScrap(p);
                      modalOnOpen();
                    }}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyState
                titleText="Os seus anúncios irão aparecer aqui"
                descriptionText="Para criar um novo anúncio de sucata clique no botão abaixo"
                onClick={() => {
                  if (meetalUser.onboarding_finished) {
                    navigate("/admin/industria/nova-sucata");
                  } else {
                    toast({
                      title: "Atenção",
                      description:
                        "Antes de criar seu primeiro anúncio você deve configurar o pagamento.",
                      status: "warning",
                      position: "bottom-right",
                      isClosable: true,
                    });
                  }
                }}
                buttonText="Criar meu primeiro anúncio"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
      <AdminModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        headerText="Excluir anúncio"
        bodyText="Tem certeza que deseja excluir esse anúncio?"
        closeButtonOnClick={modalOnClose}
        closeButtonText="Não"
        acceptButtonOnClick={() => {
          deleteScrapPost();
          modalOnClose();
        }}
        acceptButtonText="Sim, excluir"
      />
      <OffersDrawer
        isOpen={drawerIsOpen}
        onClose={drawerOnClose}
        offers={offers}
        chosenScrap={chosenScrap}
        buttonAccept={acceptOffer}
        chosenOfferId={chosenOfferId}
        setChosenOfferId={setChosenOfferId}
        buttonDisabled={buttonDisabled}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
