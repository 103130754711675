import { Navigate } from "react-router-dom";

export default function UserTypeSucateiroAuth({ children }) {
  const user = JSON.parse(window.localStorage.getItem("MeetalUser"));

  if (!user || user.user_type !== "Sucateiro") {
    return <Navigate to="/entrar" />;
  }

  return <>{children}</>;
}
