import { useContext, useEffect, useState } from "react";
import { HStack, VStack, useToast } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import { createColeta, getContracts } from "../../services/Contracts";
import {
  ContractCard,
  CreateCollectionDrawer,
} from "../../components/AdminComponents";
import { useDisclosure } from "@chakra-ui/react";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import dayjs from "dayjs";
import ptBr from "dayjs/locale/pt-br";
import dayjsBusinessDays from "dayjs-business-days";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import { useNavigate } from "react-router-dom";

export default function IndustriaMyContracts() {
  const [fullDumpsterRadio, setFullDumpsterRadio] = useState("");
  const [loadingEquipmentRadio, setLoadingEquipmentRadio] = useState("");
  const [emptyDumpsterRadio, setEmptyDumpsterRadio] = useState("");
  const [contracts, setContracts] = useState([]);
  const [chosenContract, setChosenContract] = useState({});
  const [materialsList, setMaterialsList] = useState([]);
  const [materials, setMaterials] = useState([
    {
      name_and_type: "",
      quantity: "",
      price: "",
    },
  ]);
  const [chosenDate, setChosenDate] = useState("");
  const [dateOptions, setDateOptions] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  dayjs.extend(dayjsBusinessDays);
  const toast = useToast();

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    const response = await getContracts(
      data.idToken.jwtToken,
      meetalUser.meetal_user_id,
      meetalUser.user_type,
      logOut
    );
    setPageLoading(false);
    setContracts(response);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let temp = true;
    for (let key in dateOptions) {
      if (dateOptions[key].selected) {
        temp = false;
        break;
      }
    }
    setButtonDisabled(temp);
  }, [dateOptions]);

  useEffect(() => {
    if (fullDumpsterRadio === "Caçamba do comprador") {
      setLoadingEquipmentRadio("");
    }
  }, [fullDumpsterRadio]);

  let initialDate = dayjs(dayjs()).locale(ptBr).businessDaysAdd(2);
  const days = [initialDate];
  for (let i = 0; i < 4; i++) {
    let nextDay = dayjs(days[i]).nextBusinessDay();
    days.push(nextDay);
  }

  function createDateOptions() {
    const optionsDict = {};

    days.forEach((d) => {
      optionsDict[d.format("YYYY-MM-DD")] = { period: [], selected: false };
    });
    setDateOptions(optionsDict);
  }

  useEffect(() => {
    createDateOptions();
  }, [chosenContract]);

  function handleChange(index, { name, value }) {
    if (name === "material") {
      const updatedMaterials = materials.map((material, i) => {
        if (i === index) {
          return { ...material, name_and_type: value };
        }
        return material;
      });

      setMaterials(updatedMaterials);
    } else {
      const updatedMaterials = materials.map((material, i) => {
        if (i === index) {
          return { ...material, [name]: Number(value) };
        }
        return material;
      });

      setMaterials(updatedMaterials);
    }
  }

  function filterDateOptions() {
    const selectedDateOptions = Object.fromEntries(
      Object.entries(dateOptions).filter(([key, d]) => d.selected)
    );

    for (let key in selectedDateOptions) {
      delete selectedDateOptions[key].selected;
    }

    return selectedDateOptions;
  }

  function validateCollectionData() {
    if (!fullDumpsterRadio) {
      toast({
        title: "Erro ao solicitar coleta",
        description: "Você deve responder à todas as perguntas",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }
    if (!emptyDumpsterRadio) {
      toast({
        title: "Erro ao solicitar coleta",
        description: "Você deve responder à todas as perguntas",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }
    for (let i = 0; i < materials.length; i++) {
      if (!materials[i].name_and_type || !materials[i].quantity) {
        toast({
          title: "Erro ao solicitar coleta",
          description:
            "Você deve selecionar ao menos um material e informar seu peso estimado",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  }

  function addPriceToMaterials() {
    const materialsWithPrice = {};

    for (let i = 0; i < chosenContract.materials.length; i++) {
      const key = chosenContract.materials[i].name;
      const value = chosenContract.materials[i].price;
      materialsWithPrice[key] = value;
    }

    return materials.map((m) => {
      const name = m.name_and_type.split(" ");
      const price = materialsWithPrice[name[0]];
      return { ...m, price };
    });
  }

  async function requestCollection() {
    setButtonLoading(true);
    const validatedData = validateCollectionData();

    if (validatedData) {
      const selectedDateOptions = filterDateOptions();
      const materialsEstimation = addPriceToMaterials();
      const body = {
        sucata_id: chosenContract.sucata_id,
        contract_id: chosenContract.contract_id,
        materials_estimation_industria: materialsEstimation,
        leave_empty_dumpster: emptyDumpsterRadio,
        full_dumpster: fullDumpsterRadio,
        loading_equipment: loadingEquipmentRadio,
        sucateiro_id: chosenContract.sucateiro_id,
        industria_id: chosenContract.industria_id,
        date_options: selectedDateOptions,
        industria_cnpj_nome: chosenContract.industria_cnpj_nome,
        sucateiro_cnpj_nome: chosenContract.sucateiro_cnpj_nome,
        scrap_info: chosenContract.scrap_info,
        email_industria: meetalUser.user_email,
        industriaApiKey: meetalUser.asaas_apiKey,
      };
      const response = await createColeta(data.idToken.jwtToken, body, logOut);
      if (response.data.body === "Coleta criada") {
        toast({
          title: "Solicitação de coleta enviada com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        fetchData();
        onClose();
      } else {
        toast({
          title: "Erro ao solicitar coleta",
          description:
            "Ocorreu um erro ao fazer a solicitação, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      }
      fetchData();
      onClose();
    }
    setButtonLoading(false);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Meus Contratos</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : contracts.length > 0 ? (
              <VStack>
                {contracts.map((c) => (
                  <ContractCard
                    key={c.contract_id}
                    images={c.scrapImages}
                    id={c.contract_id}
                    user_type={meetalUser.user_type}
                    cnpj_nome={c.sucateiro_cnpj_nome}
                    scrap_info={c.scrap_info}
                    value={c.valor_recebido}
                    status={c.contract_status}
                    buttonRequestCollection={() => {
                      setChosenDate("");
                      setFullDumpsterRadio("");
                      setEmptyDumpsterRadio("");
                      createDateOptions();
                      setChosenContract(c);
                      setMaterialsList(
                        c.materials.map((m) => {
                          return {
                            name: `${m.name} / ${m.type}`,
                            value: `${m.name} / ${m.type}`,
                          };
                        })
                      );
                      onOpen();
                    }}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyState
                titleText="Os seus contratos irão aparecer aqui"
                descriptionText="Os contratos são gerados automaticamente após você aceitar uma oferta recebida de um comprador."
                onClick={() => navigate("/admin/industria/meus-anuncios")}
                buttonText="Aceitar ofertas"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
      <CreateCollectionDrawer
        isOpen={isOpen}
        onClose={onClose}
        days={days}
        chosenDate={chosenDate}
        setChosenDate={setChosenDate}
        dateOptions={dateOptions}
        setDateOptions={setDateOptions}
        fullDumpsterRadio={fullDumpsterRadio}
        setFullDumpsterRadio={setFullDumpsterRadio}
        loadingEquipmentRadio={loadingEquipmentRadio}
        setLoadingEquipmentRadio={setLoadingEquipmentRadio}
        emptyDumpsterRadio={emptyDumpsterRadio}
        setEmptyDumpsterRadio={setEmptyDumpsterRadio}
        materialsList={materialsList}
        setMaterialsList={setMaterialsList}
        materials={materials}
        setMaterials={setMaterials}
        handleChange={handleChange}
        addMaterial={() =>
          setMaterials([
            ...materials,
            {
              name_and_type: "",
              quantity: "",
              price: "",
            },
          ])
        }
        requestCollection={requestCollection}
        buttonDisabled={buttonDisabled}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
