import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";
import { dowloadAndAddPhotoToScrap } from "./S3";

export async function getContracts(idToken, user_id, user_type, logOut) {
  const url = `https://oq46ndke26.execute-api.sa-east-1.amazonaws.com/dev/contracts?user_id=${user_id}&user_type=${user_type}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  if (response && response.data && response.data.body) {
    const result = await dowloadAndAddPhotoToScrap(
      JSON.parse(response.data.body)
    );
    return result;
  }
}

export async function createColeta(idToken, body, logOut) {
  const url = `https://oq46ndke26.execute-api.sa-east-1.amazonaws.com/dev/contracts`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}
