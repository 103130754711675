import { useContext, useState } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  SessionButton,
  SessionFormLabel,
  SessionInput,
} from "../../components/SessionComponents";
import { postSucata } from "../../services/Sucatas";
import { FormControl } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../services/S3";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import SubHeader from "../../components/Global/SubHeader";
import AdminPageContent from "../../components/Global/AdminPageContent";
import {
  MaterialCard,
  NewScrapCheckBox,
  PageDivider,
  WhiteContainer,
  WhiteContainerHeading,
  WhiteContainerText,
} from "../../components/AdminComponents";
import Footer from "../../components/Global/Footer";
import {
  AlertComponent,
  InputFileComponent,
  NumberInputComponent,
  NumberInputWithStepperComponent,
  OutlineButtonComponent,
  SelectComponent,
  TagComponent,
  TextareaComponent,
} from "../../components/GlobalComponents";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import { brandColorBlue } from "../../GlobalStyles";

export default function IndustriaNewScrap() {
  const [formData, setFormData] = useState({
    title: "",
    branch: "",
    files: [],
    materials: [],
    has_impurities: "",
    n_empty_dumpsters: "",
    equipment: "",
    extra_info: "",
    duration_seconds: "",
    phone: "",
    email: "",
    current_bid: 0,
  });
  const [images, setImages] = useState([]);
  const [materials, setMaterials] = useState([
    {
      name: "",
      type: "",
      quantity: "",
      price: 0,
    },
  ]);
  const [radioHasImpurities, setRadioHasImpurities] = useState("");
  const [radioRealPhotos, setRadioRealPhotos] = useState("");
  const [phoneCheckbox, setPhoneCheckbox] = useState(false);
  const [emailCheckbox, setEmailCheckbox] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [controlState, setControlState] = useState(false);

  const auctionDurationOptions = [
    {
      name: "30 seg",
      value: 30 / 86400,
    },
    {
      name: "2 horas",
      value: 7200 / 86400,
    },
    {
      name: "1 dia",
      value: 1,
    },
    {
      name: "5 dias",
      value: 5,
    },
    {
      name: "10 dias",
      value: 10,
    },
    {
      name: "15 dias",
      value: 15,
    },
    {
      name: "20 dias",
      value: 20,
    },
    {
      name: "25 dias",
      value: 25,
    },
  ];
  const numberOfSecondsInDay = 86400;

  const { logOut } = useContext(CognitoSessionContext);

  const navigate = useNavigate();
  const toast = useToast();

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function sendImages() {
    const arrayFiles = [];
    images.forEach(async (image) => {
      const fileKey = uuidv4();
      arrayFiles.push(fileKey);
      await uploadFile(fileKey, image.file, "sucata-files");
    });
    return arrayFiles;
  }

  function handleImages(e) {
    const filesArray = images;
    for (let i = 0; i < e.target.files.length; i++) {
      const fileObject = {
        file: e.target.files[i],
        url: URL.createObjectURL(e.target.files[i]),
      };
      filesArray.push(fileObject);
    }
    setImages(filesArray);
    setControlState(!controlState);
  }

  function handleMaterials(index, { name, value }) {
    if (name === "price") {
      const formattedValue = formatPrice(value.target.value);

      const updatedMaterials = materials.map((material, i) => {
        if (i === index) {
          return { ...material, [name]: formattedValue };
        }
        return material;
      });

      setMaterials(updatedMaterials);
    } else {
      const updatedMaterials = materials.map((material, i) => {
        if (i === index) {
          return { ...material, [name]: value };
        }
        return material;
      });

      setMaterials(updatedMaterials);
    }
  }

  function calculateWeight(materials) {
    let totalWeight = 0;
    materials.map((m) => {
      totalWeight += Number(m.quantity);
    });
    return totalWeight;
  }

  function validateMaterials() {
    for (let i = 0; i < materials.length; i++) {
      if (!materials[i].name || !materials[i].type || !materials[i].quantity) {
        return false;
      }
    }
    return true;
  }

  function validateScrap() {
    if (!formData.title) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "O campo de 'Título do anúncio' deve ser preenchido",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (images.length <= 0) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "Adicione ao menos uma foto",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (images.length > 6) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "Só é permitido mandar até 6 fotos",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (!radioRealPhotos) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "Você deve preencher o tipo das fotos",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    const materialsValidated = validateMaterials();
    if (!materialsValidated) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "Todos os campos de 'Materiais' são obrigatórios",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (!radioHasImpurities) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "O campo de 'Possui impurezas' deve ser preenchido",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (formData.n_empty_dumpsters === "") {
      toast({
        title: "Erro ao publicar anuncio",
        description: "O campo de 'Caçambas vazias' deve ser preenchido",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    if (!formData.duration_seconds) {
      toast({
        title: "Erro ao publicar anuncio",
        description: "Selecione o tempo de duração do leilão",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }

    return true;
  }

  async function handlePostSucata() {
    setButtonIsLoading(true);
    const scrapValidated = validateScrap();

    if (scrapValidated) {
      const files = sendImages();
      const response = await postSucata(
        data.idToken.jwtToken,
        {
          ...formData,
          location: {
            state: meetalUser.Estado,
            city: meetalUser.Cidade,
            neighborhood: meetalUser.Bairro,
            street: meetalUser.Rua,
            number: meetalUser["Número"],
            complement: meetalUser.Complemento,
          },
          branch: meetalUser["Nome da unidade geradora de sucata"],
          files,
          is_photo_real: radioRealPhotos === "true" ? true : false,
          materials: materials,
          total_weight: calculateWeight(materials),
          has_impurities: radioHasImpurities,
          phone: phoneCheckbox ? meetalUser.celular : "",
          email: emailCheckbox ? meetalUser.user_email : "",
          nome_cnpj: meetalUser.nome_cnpj,
          owner_id: meetalUser.meetal_user_id,
          is_test: meetalUser.is_test ? meetalUser.is_test : false,
          has_scale:
            meetalUser["Possui balança no local?"] === "Sim" ? true : false,
          scale_capacity:
            meetalUser[
              "Caso possua balança, qual é a capacidade máxima em toneladas?"
            ],
          site_equipment:
            meetalUser["Possui equipamentos de carregamento no local?"],
        },
        logOut
      );

      console.log(response);
      if (response.data.statusCode !== 200) {
        toast({
          title: "Ocorreu uma falha na comunicação",
          description: "Não foi possível publicar a sua venda, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      } else {
        toast({
          title: "Publicado com sucesso",
          description: "Seu anúncio foi publicado com sucesso e está ativo",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        navigate("/admin/industria/meus-anuncios");
      }
    }
    setButtonIsLoading(false);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Novo Anúncio</SubHeader>
          <AdminPageContent>
            <HStack alignItems="start">
              <WhiteContainer
                width={{ md: "42vw", lg: "43vw", xl: "45vw", "2xl": "52vw" }}
              >
                <WhiteContainerHeading>
                  Informações sobre Sucata
                </WhiteContainerHeading>
                <FormControl isRequired>
                  <SessionFormLabel>Titulo do anúncio</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite um título para o seu anúncio"
                    type="text"
                    name="title"
                    maxlength="60"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </FormControl>
                <SessionFormLabel>Unidade geradora de sucata</SessionFormLabel>
                <WhiteContainerText isLabel={true}>
                  {meetalUser["Nome da unidade geradora de sucata"]}
                </WhiteContainerText>
                <FormControl isRequired>
                  <HStack alignItems="center" margin="25px 0">
                    <WhiteContainerText isLabel={true}>
                      Fotos
                    </WhiteContainerText>
                    <TagComponent
                      size="sm"
                      fontSize="sm"
                      color="gray.800"
                      backgroundColor="gray.100"
                      borderRadius="2px"
                    >
                      Adicione ao menos uma foto (até 6 fotos)
                    </TagComponent>
                  </HStack>
                  <InputFileComponent multiple={true} onChange={handleImages} />
                  <SimpleGrid minChildWidth="230px" marginTop="10px" gap="15px">
                    {images.map((i, index) => (
                      <Box key={i.file.name} position="relative">
                        {index === 0 ? (
                          <>
                            <TagComponent
                              size="sm"
                              fontSize="xs"
                              color="gree.800"
                              backgroundColor="green.100"
                              borderRadius="6px"
                              position="absolute"
                              top="10px"
                              left="10px"
                              zIndex="2"
                            >
                              Foto de capa
                            </TagComponent>
                          </>
                        ) : (
                          <></>
                        )}
                        <Image
                          src={i.url}
                          width="230px"
                          height="170px"
                          borderRadius="8px"
                          objectFit="cover"
                          position="relative"
                          zIndex="1"
                          _hover={{
                            zIndex: "0",
                          }}
                        />
                        <Button
                          size="sm"
                          leftIcon={<FiTrash2 />}
                          color={brandColorBlue}
                          backgroundColor="white"
                          position="absolute"
                          bottom="10px"
                          left="10px"
                          zIndex="0"
                          _hover={{
                            zIndex: "1",
                          }}
                          onClick={() => {
                            const newImagesArray = images.filter(
                              (image) => image.file.name !== i.file.name
                            );
                            setImages(newImagesArray);
                          }}
                        >
                          Remover
                        </Button>
                      </Box>
                    ))}
                  </SimpleGrid>
                  <FormControl isRequired>
                    <SessionFormLabel>As fotos enviadas são:</SessionFormLabel>
                    <RadioGroup
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                      value={radioRealPhotos}
                      onChange={setRadioRealPhotos}
                    >
                      <Radio value="true">Fotos reais do material</Radio>
                      <Radio value="false">Fotos ilustrativas</Radio>
                    </RadioGroup>
                  </FormControl>
                </FormControl>
                <PageDivider />
                <WhiteContainerHeading>Materiais</WhiteContainerHeading>
                {materials.map((m, index) => (
                  <MaterialCard key={index}>
                    <HStack>
                      <FormControl isRequired>
                        <SessionFormLabel>Material</SessionFormLabel>
                        <SessionInput
                          placeholder="Material"
                          type="text"
                          name="name"
                          value={m.name}
                          onChange={(e) => handleMaterials(index, e.target)}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <SessionFormLabel>Tipo</SessionFormLabel>
                        <SessionInput
                          placeholder="Tipo"
                          type="text"
                          name="type"
                          value={m.type}
                          onChange={(e) => handleMaterials(index, e.target)}
                        />
                      </FormControl>
                    </HStack>
                    <HStack>
                      <FormControl isRequired>
                        <SessionFormLabel>
                          Qtd total em kg (aproximada)
                        </SessionFormLabel>
                        <NumberInputComponent
                          width="50%"
                          name="quantity"
                          value={m.quantity}
                          onChange={(e) => {
                            const target = {
                              name: "quantity",
                              value: e,
                            };
                            handleMaterials(index, target);
                          }}
                          precision={2}
                          placeholder="Quantidade"
                        />
                      </FormControl>
                    </HStack>
                    {index > 0 ? (
                      <OutlineButtonComponent
                        size="md"
                        icon={<FiTrash2 />}
                        hasMargin={true}
                        onClick={() => {
                          materials.splice(index, 1);
                          setMaterials(materials);
                        }}
                      >
                        Remover
                      </OutlineButtonComponent>
                    ) : (
                      <></>
                    )}
                  </MaterialCard>
                ))}
                <OutlineButtonComponent
                  size="lg"
                  icon={<FiPlus />}
                  onClick={() =>
                    setMaterials([
                      ...materials,
                      {
                        name: "",
                        type: "",
                        quantity: "",
                        price: 0,
                      },
                    ])
                  }
                >
                  Adicionar material
                </OutlineButtonComponent>
                <FormControl isRequired>
                  <SessionFormLabel>Possui impurezas?</SessionFormLabel>
                  <RadioGroup
                    display="flex"
                    flexDirection="column"
                    value={radioHasImpurities}
                    onChange={setRadioHasImpurities}
                  >
                    <Radio value="Não">Não</Radio>
                    <Radio value="Sim">Sim</Radio>
                    <Radio value="Pode haver">Pode haver</Radio>
                  </RadioGroup>
                </FormControl>
                <PageDivider />
                <WhiteContainerHeading>Coleta</WhiteContainerHeading>
                <FormControl isRequired>
                  <SessionFormLabel>
                    Quantas caçambas vazias o comprador deverá deixar no local?
                  </SessionFormLabel>
                  <NumberInputWithStepperComponent
                    placeholder="Digite o número de caçambas vazias"
                    name="n_empty_dumpsters"
                    value={formData.n_empty_dumpsters}
                    onChange={(e) => {
                      const target = {
                        target: { name: "n_empty_dumpsters", value: Number(e) },
                      };
                      handleChange(target);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>
                    É necessário algum equipamento especifico para coleta?
                  </SessionFormLabel>
                  <TextareaComponent
                    placeholder="Exemplo: Caminhão munck, garra sucateira, etc.."
                    name="equipment"
                    value={formData.equipment}
                    onChange={handleChange}
                  />
                </FormControl>
                <PageDivider />
                <FormControl>
                  <SessionFormLabel>Observações extras</SessionFormLabel>
                  <TextareaComponent
                    placeholder="Qualquer informação extra que seja pertinente"
                    name="extra_info"
                    value={formData.extra_info}
                    onChange={handleChange}
                  />
                </FormControl>
              </WhiteContainer>

              <WhiteContainer width="28%">
                <WhiteContainerHeading>
                  Configurações do anúncio
                </WhiteContainerHeading>
                <FormControl>
                  <SessionFormLabel>Duração do leilão</SessionFormLabel>
                  <SelectComponent
                    placeholder="Selecione a duração do leilão"
                    name="duration_seconds"
                    onChange={(e) => {
                      const target = {
                        target: {
                          name: "duration_seconds",
                          value: e.target.value * numberOfSecondsInDay,
                        },
                      };
                      handleChange(target);
                    }}
                    options={auctionDurationOptions}
                  />
                </FormControl>
                <AlertComponent
                  title="Atenção"
                  description="Você terá 7 dias para aceitar a oferta de preferência ao fim do leilão. Após esse período, as ofertas serão excluídas."
                  status="warning"
                />
                <PageDivider />
                <SessionFormLabel>Exibir dados de contato?</SessionFormLabel>
                <VStack alignItems="start">
                  <NewScrapCheckBox
                    onChange={(e) => setPhoneCheckbox(e.target.checked)}
                  >
                    WhatsApp e Telefone
                  </NewScrapCheckBox>
                  <NewScrapCheckBox
                    onChange={(e) => setEmailCheckbox(e.target.checked)}
                  >
                    Email
                  </NewScrapCheckBox>
                </VStack>
                <PageDivider />
                <SessionButton
                  onClick={handlePostSucata}
                  isLoading={buttonIsLoading}
                >
                  Publicar anúncio
                </SessionButton>
              </WhiteContainer>
            </HStack>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
