import { CognitoUserPool } from "amazon-cognito-identity-js";

// const poolData = {
//   UserPoolId: "us-east-2_eUDDQ3oWS",
//   ClientId: "7l72d6aa48ksppfnv9vja48d49",
// };

const poolData = {
  UserPoolId: "sa-east-1_Fzd5Fl0vF",
  ClientId: "5mvhu9v2qjlkcsuogcsvqghth1",
};

export default new CognitoUserPool(poolData);
