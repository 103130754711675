import { useContext, useEffect } from "react";
import { CognitoSessionContext } from "../contexts/CognitoSession";
import { CognitoUserContext } from "../contexts/CognitoUser";
import { useNavigate } from "react-router-dom";

export default function CheckSession({ children }) {
  const navigate = useNavigate();

  const { getSession } = useContext(CognitoSessionContext);
  const { setUserData } = useContext(CognitoUserContext);

  useEffect(() => {
    getSession().then((session) => {
      console.log("session", session);
      setUserData(session);
      if (session) {
        const { user_type } = JSON.parse(
          window.localStorage.getItem("MeetalUser")
        );
        if (user_type === "Industria") {
          navigate("/admin/industria/meus-anuncios");
        } else {
          navigate("/admin/sucateiro/anuncios");
        }
      }
    });
  }, []);

  return <>{children}</>;
}
