import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function getUser(path, idToken, email) {
  const url = `https://099qib60w5.execute-api.sa-east-1.amazonaws.com/dev${path}?email=${email.toLowerCase()}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const response = await axios.get(url, config);
  window.localStorage.setItem(
    "MeetalUser",
    JSON.stringify(response.data["body-json"].user_metadata)
  );

  return response;
}

export async function updateUser(idToken, body, logOut) {
  const url = `https://099qib60w5.execute-api.sa-east-1.amazonaws.com/dev/user_metadata?`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const jsonBody = JSON.stringify(body);

  const promise = axios.post(url, jsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}
