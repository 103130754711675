import { Button, Container, Icon, Text, VStack } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { brandColorBlue } from "../../GlobalStyles";

export default function EmptyState({
  titleText,
  descriptionText,
  onClick,
  buttonText,
}) {
  return (
    <Container
      maxWidth="none"
      marginTop="150px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="25px"
    >
      <Icon
        as={FiSearch}
        boxSize={20}
        color={brandColorBlue}
        borderRadius="50%"
        backgroundColor="gray.100"
        padding="10px"
      />
      <VStack gap="10px">
        <Text fontSize="xl" fontWeight="600" color={brandColorBlue}>
          {titleText}
        </Text>
        <Text fontSize="md" color="gray.600">
          {descriptionText}
        </Text>
      </VStack>
      {onClick ? (
        <Button
          size="md"
          fontWeight="600"
          variant="link"
          textDecoration="underline"
          color={brandColorBlue}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      ) : (
        <></>
      )}
    </Container>
  );
}
