import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./pages/LogIn/LogIn";
import CognitoUserProvider from "./contexts/CognitoUser";
import CognitoSessionProvider from "./contexts/CognitoSession";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/LogIn/ForgotPassword";
import EmailConfirmation from "./pages/SignUp/EmailConfirmation";
import Authenticator from "./components/Autheticator";
import IndustriaNewScrap from "./pages/Admin/IndustriaNewScrap";
import IndustriaMyScrapPosts from "./pages/Admin/IndustriaMyScrapPosts";
import UnderReview from "./pages/LogIn/UnderReview";
import UserTypeIndustriaAuth from "./components/UserTypeAuth/UserTypeIndustriaAuth";
import SucateiroAllScraps from "./pages/Admin/SucateiroAllScraps";
import CheckSession from "./components/CheckSession";
import UserTypeSucateiroAuth from "./components/UserTypeAuth/UserTypeSucateiroAuth";
import IndustriaPageTest from "./pages/Admin/IndustriaPageTest";
import IndustriaMyContracts from "./pages/Admin/IndustriaMyContracts";
import SucateiroMyContracts from "./pages/Admin/SucateiroMyContracts";
import IndustriaMyCollections from "./pages/Admin/IndustriaMyCollections";
import SucateiroMyCollections from "./pages/Admin/SucateiroMyCollections";
import IndustriaSettings from "./pages/Admin/IndustriaSettings";
import SucateiroSettings from "./pages/Admin/SucateiroSettings";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <CognitoUserProvider>
          <CognitoSessionProvider>
            <Routes>
              <Route
                path="/admin/industria/teste"
                element={<IndustriaPageTest />}
              ></Route>
              <Route
                path="/cadastro"
                element={
                  <CheckSession>
                    <SignUp />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar"
                element={
                  <CheckSession>
                    <LogIn />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/cadastro/verificar-conta"
                element={
                  <CheckSession>
                    <EmailConfirmation />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar/esqueci-senha"
                element={
                  <CheckSession>
                    <ForgotPassword />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar/em-analise"
                element={
                  <CheckSession>
                    <UnderReview />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/admin/industria/nova-sucata"
                element={
                  <Authenticator>
                    <UserTypeIndustriaAuth>
                      <IndustriaNewScrap />
                    </UserTypeIndustriaAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/industria/meus-anuncios"
                element={
                  <Authenticator>
                    <UserTypeIndustriaAuth>
                      <IndustriaMyScrapPosts />
                    </UserTypeIndustriaAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/industria/meus-contratos"
                element={
                  <Authenticator>
                    <UserTypeIndustriaAuth>
                      <IndustriaMyContracts />
                    </UserTypeIndustriaAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/industria/minhas-coletas"
                element={
                  <Authenticator>
                    <UserTypeIndustriaAuth>
                      <IndustriaMyCollections />
                    </UserTypeIndustriaAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/industria/configuracoes"
                element={
                  <Authenticator>
                    <UserTypeIndustriaAuth>
                      <IndustriaSettings />
                    </UserTypeIndustriaAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/sucateiro/anuncios"
                element={
                  <Authenticator>
                    <UserTypeSucateiroAuth>
                      <SucateiroAllScraps />
                    </UserTypeSucateiroAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/sucateiro/meus-contratos"
                element={
                  <Authenticator>
                    <UserTypeSucateiroAuth>
                      <SucateiroMyContracts />
                    </UserTypeSucateiroAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/sucateiro/minhas-coletas"
                element={
                  <Authenticator>
                    <UserTypeSucateiroAuth>
                      <SucateiroMyCollections />
                    </UserTypeSucateiroAuth>
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/sucateiro/configuracoes"
                element={
                  <Authenticator>
                    <UserTypeSucateiroAuth>
                      <SucateiroSettings />
                    </UserTypeSucateiroAuth>
                  </Authenticator>
                }
              ></Route>
            </Routes>
          </CognitoSessionProvider>
        </CognitoUserProvider>
      </BrowserRouter>
    </>
  );
}
