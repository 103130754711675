import { useContext, useEffect, useState } from "react";
import { HStack, VStack, useToast } from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import {
  CollectionCard,
  ScheduleCollectionDrawer,
  SendWeightDrawer,
} from "../../components/AdminComponents";
import {
  getCollections,
  postScheduleCollection,
} from "../../services/Collections";
import { useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import { handlePayment } from "../../services/Payment";
import Footer from "../../components/Global/Footer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import { v4 as uuidv4 } from "uuid";
import { uploadFile } from "../../services/S3";
import EmptyState from "../../components/Global/EmptyState";
import LoadingState from "../../components/Global/LoadingState";
import { useNavigate } from "react-router-dom";

export default function SucateiroMyCollections() {
  const [collections, setCollections] = useState([]);
  const [chosenCollection, setChosenCollection] = useState({});
  const [materials, setMaterials] = useState([]);
  const [currentPrice, setCurrentPrice] = useState("");
  const [currentWeight, setCurrentWeight] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [selectedDate, setselectedDate] = useState({
    day: "",
    period: "",
  });
  const [image, setImage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const {
    isOpen: scheduleCollectionDrawerIsOpen,
    onOpen: scheduleCollectionDrawerOnOpen,
    onClose: scheduleCollectionDrawerOnClose,
  } = useDisclosure();
  const {
    isOpen: sendWeightDrawerIsOpen,
    onOpen: sendWeightDrawerOnOpen,
    onClose: sendWeightDrawerOnClose,
  } = useDisclosure();

  const toast = useToast();

  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchData() {
    const response = await getCollections(
      data.idToken.jwtToken,
      meetalUser.meetal_user_id,
      meetalUser.user_type,
      logOut
    );
    setPageLoading(false);
    //console.log(JSON.parse(response.data.body));
    setCollections(JSON.parse(response.data.body));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDate.day) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [selectedDate]);

  function calculatePrice(materials) {
    const materialsPrices = materials.map((m) => {
      const purity = (100 - m.impurities) / 100;
      return m.impurities
        ? m.quantity * m.price * purity
        : m.quantity * m.price;
    });

    const bid = materialsPrices.reduce((acumulador, elemento) => {
      return acumulador + elemento;
    }, 0);

    setCurrentPrice(bid.toFixed(2));
  }

  function calculateWeight(materials) {
    let totalWeight = 0;
    materials.forEach((m) => {
      totalWeight += Number(m.quantity);
    });

    setCurrentWeight(totalWeight);
  }

  function handleMaterials(index, { name, value }) {
    const updatedMaterials = materials.map((material, i) => {
      if (i === index) {
        return { ...material, [name]: value };
      }
      return material;
    });

    calculatePrice(updatedMaterials);
    calculateWeight(updatedMaterials);
    setMaterials(updatedMaterials);
  }

  async function scheduleCollection() {
    setButtonLoading(true);
    const formattedDate = {
      day: dayjs(selectedDate.day).format("DD/MM/YYYY"),
      period: selectedDate.period === "Manhã" ? "08:00-12:00" : "13:00-18:00",
    };
    const body = {
      coleta_id: chosenCollection.coleta_id,
      coleta_date: formattedDate,
    };
    const response = await postScheduleCollection(
      data.idToken.jwtToken,
      body,
      logOut
    );
    if (response.data.body === "Coleta Agendada") {
      toast({
        title: "Agendamento confirmado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      fetchData();
      scheduleCollectionDrawerOnClose();
    } else {
      toast({
        title: "Ocorreu uma falha na comunicação",
        description: "Não foi possível agendar a coleta, tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  function validateWeight() {
    for (let i = 0; i < materials.length; i++) {
      if (!materials[i].quantity) {
        toast({
          title: "Erro ao enviar pesagem",
          description: "O peso deve ser maior que zero",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
        return false;
      }
    }

    return true;
  }

  async function sendImage() {
    const fileKey = uuidv4();
    await uploadFile(fileKey, image, "ticket-pesagem");
    return fileKey;
  }

  function formatMaterials() {
    return materials.map((m) => {
      const nameAndType = m.name_and_type.split(" ");
      if (m.impurities) {
        delete m.name_and_type;
        return {
          ...m,
          name: nameAndType[0],
          type: nameAndType[2],
        };
      } else {
        delete m.name_and_type;
        return {
          ...m,
          name: nameAndType[0],
          type: nameAndType[2],
          impurities: 0,
        };
      }
    });
  }

  async function finishCollection() {
    setButtonLoading(true);
    const validatedWeight = validateWeight();
    if (validatedWeight) {
      const ticketPesagem = await sendImage();
      const formattedMaterials = formatMaterials();
      const response = await handlePayment(
        currentPrice,
        chosenCollection.contract_id,
        chosenCollection.coleta_id,
        chosenCollection.industriaApiKey,
        chosenCollection.email_industria,
        ticketPesagem,
        currentWeight,
        formattedMaterials
      );
      if (response.data.statusCode === 200) {
        toast({
          title: "Pesagem enviada com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        fetchData();
        sendWeightDrawerOnClose();
      } else {
        toast({
          title: "Erro ao enviar pesagem, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
        fetchData();
      }
    }
    setButtonLoading(false);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Minhas coletas</SubHeader>
          <AdminPageContent>
            {pageLoading ? (
              <LoadingState />
            ) : collections.length > 0 ? (
              <VStack>
                {collections.map((c) => (
                  <CollectionCard
                    key={c.coleta_id}
                    id={c.coleta_id}
                    user_type={meetalUser.user_type}
                    sucateiro_cnpj_nome={c.sucateiro_cnpj_nome}
                    industria_cnpj_nome={c.industria_cnpj_nome}
                    total_weight={c.total_weight}
                    valor_coleta={c.valor_coleta}
                    status={c.coleta_status}
                    coleta_date={c.coleta_date}
                    buttonScheduleCollection={() => {
                      setChosenDate("");
                      setselectedDate({
                        day: "",
                        period: "",
                      });
                      setChosenCollection(c);
                      scheduleCollectionDrawerOnOpen();
                    }}
                    buttonSendWeight={() => {
                      setChosenCollection(c);
                      sendWeightDrawerOnOpen();
                      calculatePrice(c.materials_estimation_industria);
                      calculateWeight(c.materials_estimation_industria);
                      setMaterials(c.materials_estimation_industria);
                    }}
                    buttonPayCollection={() => {
                      window.open(c.payment_url, "_blank");
                    }}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyState
                titleText="As suas coletas irão aparecer aqui"
                descriptionText="Caso você possua um contrato ativo com algum fornecedor, ele poderá solicitar coletas conforme necessário"
                onClick={() => navigate("/admin/sucateiro/anuncios")}
                buttonText="Faça novas ofertas"
              />
            )}
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
      <ScheduleCollectionDrawer
        isOpen={scheduleCollectionDrawerIsOpen}
        onClose={scheduleCollectionDrawerOnClose}
        chosenCollection={chosenCollection}
        chosenDate={chosenDate}
        setChosenDate={setChosenDate}
        selectedDate={selectedDate}
        setselectedDate={setselectedDate}
        scheduleCollection={scheduleCollection}
        buttonDisabled={buttonDisabled}
        buttonLoading={buttonLoading}
      />
      <SendWeightDrawer
        isOpen={sendWeightDrawerIsOpen}
        onClose={sendWeightDrawerOnClose}
        chosenCollection={chosenCollection}
        materials={materials}
        handleMaterials={handleMaterials}
        currentWeight={currentWeight}
        currentPrice={currentPrice}
        image={image}
        setImage={setImage}
        finishCollection={finishCollection}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
