import { useContext, useEffect, useState } from "react";
import { CognitoUserContext } from "../../contexts/CognitoUser";
import { useNavigate } from "react-router-dom";
import CentralizedContainer from "../../components/Global/CentralizedContainer";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import LogoWithName from "../../components/Global/LogoWithName";
import {
  SessionHeading,
  SessionMailIcon,
  SessionPinInputs,
  SessionText,
  SessionTextBold,
  SessionTextButton,
} from "../../components/SessionComponents";
import { useToast } from "@chakra-ui/react";
import { getUser } from "../../services/User";
import UserPool from "../../services/UserPool";

export default function EmailConfirmation() {
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [inputIsInvalid, setInputIsInvalid] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const { userData, setUserData, userEmail, userPassword } =
    useContext(CognitoUserContext);
  const { authenticate } = useContext(CognitoSessionContext);

  async function validateCode(verificationCode) {
    setInputIsDisabled(true);
    setButtonIsDisabled(true);
    await userData.confirmRegistration(verificationCode, true, () =>
      authenticate(userEmail, userPassword)
        .then(async (data) => {
          console.log("Logged in!", data);
          setUserData(data);
          window.localStorage.setItem("CognitoUser", JSON.stringify(data));
          const meetalUser = await getUser(
            "/user_metadata",
            data.idToken.jwtToken,
            userEmail
          );
          console.log(meetalUser);
          if (meetalUser.data["body-json"].statusCode !== 200) {
            const user = UserPool.getCurrentUser();
            if (user) user.signOut();
            window.localStorage.removeItem("CognitoUser");
            window.localStorage.removeItem("MeetalUser");
            navigate("/entrar/em-analise");
          } else if (
            meetalUser.data["body-json"].user_metadata.user_type === "Industria"
          ) {
            navigate("/admin/industria/meus-anuncios");
          } else {
            navigate("/admin/sucateiro");
          }
        })
        .catch((err) => {
          setInputIsInvalid(true);
          setInputIsDisabled(false);
          setButtonIsDisabled(false);
          console.log("Failed to login", err);
        })
    );
  }

  function resendVerificationCode() {
    userData.resendConfirmationCode(() => {
      toast({
        title: "Código enviado com sucesso",
        description: "o código foi enviado para o seu e-mail",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    });
  }

  return (
    <>
      <CentralizedContainer>
        <LogoWithName />
        <SessionMailIcon />
        <SessionHeading>Enviamos um código para o seu e-mail</SessionHeading>
        <SessionText>
          Você receberá um código de verificação no seu e-mail{" "}
          <SessionTextBold>{userEmail}</SessionTextBold>, preencha abaixo
        </SessionText>
        <SessionPinInputs
          onComplete={validateCode}
          onChange={() => setInputIsInvalid(false)}
          isInvalid={inputIsInvalid}
          isDisabled={inputIsDisabled}
        />
        <SessionText>
          Não recebeu?{" "}
          <SessionTextButton
            onClick={resendVerificationCode}
            isDisabled={buttonIsDisabled}
          >
            Reenviar código
          </SessionTextButton>
        </SessionText>
      </CentralizedContainer>
    </>
  );
}
