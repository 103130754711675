import { Box, Flex } from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiFileText,
  FiTruck,
  FiLogOut,
  FiPackage,
  FiSettings,
} from "react-icons/fi";
import SidebarButton from "../SidebarButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";

export default function Sidebar() {
  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const { user_type } = JSON.parse(window.localStorage.getItem("MeetalUser"));

  return (
    <Flex
      width={{ base: "200px", lg: "240px" }}
      height="calc(100vh - 80px)"
      backgroundColor="white"
      borderRight="1px solid"
      borderColor="gray.200"
      flexDirection="column"
      justifyContent="space-between"
      position="fixed"
      top="80px"
      left="0"
      zIndex="2"
    >
      <Box paddingLeft={{ base: "0", lg: "20px" }}>
        {user_type === "Industria" ? (
          <SidebarButton
            icon={<FiTrendingUp />}
            onClick={() => navigate("/admin/industria/meus-anuncios")}
            marginTop="25px"
            selected={window.location.href.endsWith("/meus-anuncios")}
          >
            Meus anúncios
          </SidebarButton>
        ) : (
          <SidebarButton
            icon={<FiPackage />}
            onClick={() => navigate("/admin/sucateiro/anuncios")}
            marginTop="25px"
            selected={window.location.href.endsWith("/anuncios")}
          >
            Marketplace
          </SidebarButton>
        )}
        <SidebarButton
          icon={<FiFileText />}
          onClick={() =>
            navigate(`/admin/${user_type.toLowerCase()}/meus-contratos`)
          }
          marginTop="25px"
          selected={window.location.href.endsWith("/meus-contratos")}
        >
          Meus contratos
        </SidebarButton>
        <SidebarButton
          icon={<FiTruck />}
          onClick={() =>
            navigate(`/admin/${user_type.toLowerCase()}/minhas-coletas`)
          }
          marginTop="25px"
          selected={window.location.href.endsWith("/minhas-coletas")}
        >
          Minhas coletas
        </SidebarButton>
        <SidebarButton
          icon={<FiSettings />}
          onClick={() =>
            navigate(`/admin/${user_type.toLowerCase()}/configuracoes`)
          }
          marginTop="25px"
          selected={window.location.href.endsWith("/configuracoes")}
        >
          Configurações
        </SidebarButton>
      </Box>
      <Flex
        height="80px"
        width="100%"
        alignItems="center"
        paddingLeft="20px"
        backgroundColor="#FCFDFE"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <SidebarButton icon={<FiLogOut />} onClick={logOut} marginTop="0">
          Sair
        </SidebarButton>
      </Flex>
    </Flex>
  );
}
