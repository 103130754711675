import axios from "axios";
import { dowloadAndAddPhotoToScrap } from "./S3";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postSucata(idToken, body, logOut) {
  const url = `https://t321uz0g8c.execute-api.sa-east-1.amazonaws.com/dev/sucata`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getSucatas(idToken, owner_id, logOut) {
  const url = `https://t321uz0g8c.execute-api.sa-east-1.amazonaws.com/dev/sucata?owner_id=${owner_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  if (response && response.data && response.data.body) {
    const result = await dowloadAndAddPhotoToScrap(
      JSON.parse(response.data.body)
    );
    return result;
  }
}

export async function deleteSucata(idToken, sucata_id, logOut) {
  const url = `https://t321uz0g8c.execute-api.sa-east-1.amazonaws.com/dev/sucata-industria?sucata_id=${sucata_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.delete(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getAllSucatas(idToken, logOut) {
  const url = `https://t321uz0g8c.execute-api.sa-east-1.amazonaws.com/dev/sucata-sucateiro`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };

  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  if (response && response.data && response.data.body) {
    const result = await dowloadAndAddPhotoToScrap(
      JSON.parse(response.data.body)
    );
    return result;
  }
}
