import {
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { brandColorBlue } from "../../GlobalStyles";
import { FiSearch } from "react-icons/fi";
import FilterSelect from "../FilterSelect";

export default function Filter({
  inputValue,
  inputOnChange,
  setStatusFilter,
  statusFilter,
}) {
  return (
    <Flex
      width={{ base: "calc(100vw - 200px)", lg: "calc(100vw - 240px)" }}
      height={{ base: "75px", lg: "85px" }}
      backgroundColor="#FCFDFE"
      borderBottom="1px solid"
      borderColor="gray.200"
      alignItems="center"
      padding="0 1.5vw"
      position="fixed"
      top={{ base: "155px", lg: "165px" }}
      right="0"
      zIndex="1"
    >
      <InputGroup>
        <InputLeftElement>
          <Icon as={FiSearch} color="black" />
        </InputLeftElement>
        <Input
          size="md"
          width="auto"
          borderColor="gray.200"
          color={brandColorBlue}
          backgroundColor="white"
          _placeholder={{
            color: "gray.400",
          }}
          _hover={{
            borderColor: "gray.900",
          }}
          _focus={{
            borderColor: "gray.900",
            outline: "3px solid",
            outlineColor: "blue.50",
          }}
          type="text"
          placeholder="Pesquisar anúncio"
          value={inputValue}
          onChange={inputOnChange}
        />
      </InputGroup>
      <HStack>
        <FilterSelect
          placeholder="Status"
          options={["Ativo", "Expirado", "Vendido"]}
          setFilter={setStatusFilter}
          filter={statusFilter}
        />
      </HStack>
    </Flex>
  );
}
